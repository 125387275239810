.col150 {
    min-width: 150px;
}

.col180 {
    min-width: 190px;
}

.bl_sect_frame_custom_padding {
    padding: 20px;
}