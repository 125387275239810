.rotate_y_180 {
    transform: rotateY(180deg); 
}

.star_score {
    fill:#f59511;stroke:#f59511;stroke-width:0;fill-rule:evenzore;
}

.star_unscore {
    fill:#bfbfbf;stroke:#bfbfbf;stroke-width:0;fill-rule:evenzore;
}

.star_nowrap {
    white-space: nowrap;
}