@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,500,700&display=swap");
/* ===================================================
   reset
==================================================== */
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
small,
strong,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
figcaption,
figure,
footer,
header,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
}

body {
    line-height: 1;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
menu,
nav,
section {
    display: block;
}

nav ul {
    list-style: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

.none {
    display: none !important;
}

.none-d {
    display: none !important;
}

/* ===================================================
   base
==================================================== */
* {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    word-wrap: break-word;
    word-wrap: break-word;
}

html {
    height: 100%;
    font-size: 62.5%;
    -webkit-overflow-scrolling: touch;
}

body {
    font-size: 2rem;
    width: 100%;
    height: 100%;
    background: #fff;
    color: #454545;
    font-family: "Noto Sans JP", "Noto Sans CJK JP", -apple-system,
        BlinkMacSystemFont, sans-serif;
    word-wrap: break-word;
    word-wrap: break-word;
    overflow-x: hidden;
}

main {
    display: block;
    position: relative;
}

ul,
ol {
    list-style-type: none;
}

button,
input[type="button"],
input[type="submit"] {
    background: none;
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
}
input,
textarea,
select {
    font-family: "Noto Sans JP", "Noto Sans CJK JP", -apple-system,
        BlinkMacSystemFont, sans-serif;
    background: none;
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
input[type="date"],
input[type="month"] {
    position: relative;
}

input[type="date"]::-webkit-datetime-edit-fields-wrapper,
input[type="month"]::-webkit-datetime-edit-fields-wrapper {
    font-size: 2.2rem;
}

input[type="date"]::-webkit-calendar-picker-indicator,
input[type="month"]::-webkit-calendar-picker-indicator {
    position: absolute;
    right: 8px;
    background-image: none;
}
input[type="radio"] {
    margin-left: 0;
    -webkit-appearance: radio;
    -moz-appearance: radio;
    appearance: radio;
}

a {
    text-decoration: none;
}

/* ==========================================
  layout
========================================== */

.hp_dn {
    display: none;
}

.ly_cont {
    position: relative;
    width: 100%;
}

.ly_cont_side {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    background: #0098ce !important;
    -webkit-transition: -webkit-transform 0.3s;
    transition: -webkit-transform 0.3s;
    -o-transition: transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
}

.ly_cont_main {
    width: 100%;
}

/* header
===================================== */
.ly_header {
    position: relative;
    z-index: 2;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    height: 56px;
    padding-left: 30px;
    background: #fff;
    -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}
.el_headerUserInfo {
    font-size: 1.4rem;
    text-align: right;
    position: absolute;
    right: 30px;
    list-style-type: none;
    color: #777;
}

/* main contents
===================================== */
.ly_mainCont {
    position: relative;
}

.ly_mainCont_inner {
    margin: 0 5%;
    padding-bottom: 120px;
}

.ly_mainCont_grayInner {
    padding: 120px 5%;
    margin-bottom: 10%;
    background: #f9f9f9;
}

/* ====================================
  block
==================================== */
/* accordion
===================================== */
.bl_accord_header {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: left;
    z-index: 1;
    cursor: pointer;
}

.bl_accord_header img {
    margin-left: auto;
    -webkit-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s;
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

.bl_accord_header.is_active img {
    -webkit-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s;
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
}

.bl_accord_body {
    display: none;
    opacity: 0;
}

.bl_accord_body.is_active {
    display: block;
    opacity: 1;
    -webkit-animation: accordShow 0.2s linear 0s;
    animation: accordShow 0.2s linear 0s;
}

@-webkit-keyframes accordShow {
    0% {
        display: none;
        opacity: 0;
    }
    1% {
        display: block;
        opacity: 0;
    }
    100% {
        display: block;
        opacity: 1;
    }
}

@keyframes accordShow {
    0% {
        display: none;
        opacity: 0;
    }
    1% {
        display: block;
        opacity: 0;
    }
    100% {
        display: block;
        opacity: 1;
    }
}

/* breadcrumbs
===================================== */
.bl_breadcrumb {
    font-size: 2rem;
    margin: 20px 5% 20px;
    padding-right: 10px;
    position: relative;
}

.bl_breadcrumb_list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: wrap;
    flex-flow: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-right: auto;
    margin-left: auto;
}

.bl_breadcrumb_list li:after {
    content: ">";
    margin-right: 5px;
    margin-left: 5px;
    color: #bfbfbf;
}

.bl_breadcrumb_list li:last-child:after {
    content: "";
}

.bl_breadcrumb_list li a {
    display: inline-block;
    color: #bfbfbf;
    line-height: 1;
}

/* button
===================================== */
.bl_horizBtnUnit {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 30px;
}

.bl_horizBtnUnit__r {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}
.bl_horizBtnUnit__l {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}
.bl_horizBtnUnit__r > button:last-child {
    margin-right: 0;
}

.bl_horizBtnUnit__blue {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 30px;
    padding: 20px;
    background-color: #5abcdf;
    background-color: rgba(90, 188, 223, 0.26);
}

.bl_horizBtnUnit button {
    margin-right: 15px;
    margin-left: 15px;
}
.bl_horizBtnUnit__move {
    display: flex;
}
/* error
===================================== */
.bl_error img {
    vertical-align: middle;
}

/* form
===================================== */
.bl_formUnit {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.bl_formUnit .el_select {
    margin-right: 0px;
}

.bl_formUnit .el_input {
    margin-right: 0px;
}

.bl_formUnit .el_radio_txt,
.bl_formUnit .el_checkbox_txt {
    margin: 5px 10px 5px 0;
}

.bl_formUnit .el_label {
    margin-right: 20px;
}

/* file upload
===================================== */
.bl_form_file .el_checkbox_txt {
    font-size: 1.4rem;
    padding-top: 2px;
    color: #666;
}

.bl_form_file_img {
    max-width: 300px;
    max-height: 200px;
    margin-bottom: 10px;
}

.bl_form_file_btn {
    margin: 15px 0 0;
}
.el_sendFile {
    display: none;
}

/* text
===================================== */
.bl_formTxt {
    margin-bottom: 5px;
}
.bl_formTxt_btm {
    margin-top: 10px;
}
/* side navi
===================================== */
.bl_gnav {
    border-top: 1px solid #7dbed6;
}

.bl_gnav a {
    text-decoration: none;
    color: #fff;
}

.bl_gnav_item {
    position: relative;
}

.bl_gnav_item small {
    font-size: 1.6rem;
}

.bl_gnav_item.is_active .bl_gnav_btn {
    background: #0084b3 url(../Images/icon_arrow_up_w.svg) calc(100% - 27px)
        calc(50% - 4px) no-repeat;
    border-bottom: 1px solid #7dbed6;
    cursor: pointer;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.bl_gnav_item.is_active .bl_gnavChildNav_item {
    padding: 10px 0;
    -webkit-transition: border-top 0.3s ease-out, padding-top 0.3s ease-out,
        padding-bottom 0.3s ease-out;
    -o-transition: border-top 0.3s ease-out, padding-top 0.3s ease-out,
        padding-bottom 0.3s ease-out;
    transition: border-top 0.3s ease-out, padding-top 0.3s ease-out,
        padding-bottom 0.3s ease-out;
}

.bl_gnav_item.is_active .bl_gnavChildNav_link {
    padding: 10px 0 10px 130px;
    line-height: 1.5;
    opacity: 1;
    visibility: visible;
    -webkit-transition: line-height 0.3s ease-out, opacity 0.1s linear 0.1s,
        visibility 0.1s linear 0.1s, background 0.3s;
    -o-transition: line-height 0.3s ease-out, opacity 0.1s linear 0.1s,
        visibility 0.1s linear 0.1s, background 0.3s;
    transition: line-height 0.3s ease-out, opacity 0.1s linear 0.1s,
        visibility 0.1s linear 0.1s, background 0.3s;
}

.bl_gnav_link {
    font-size: 2.2rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    height: 88px;
    padding: 20px;
    background: #0098ce;
    border-bottom: 1px solid #7dbed6;
    color: #fff;
}

.bl_gnav_btn {
    font-size: 2.2rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    height: 88px;
    padding: 20px;
    background: #0098ce url(../Images/icon_arrow_down_w.svg) calc(100% - 27px)
        calc(50% + 4px) no-repeat;
    color: #fff;
}

.bl_gnav_icon {
    display: inline-block;
    width: 36px;
    margin-right: 20px;
    text-align: center;
}

.bl_gnavChildNav {
    background: #0084b3;
    border-bottom: 1px solid #7dbed6;
}

.bl_gnavChildNav_item {
    padding-top: 0;
    padding-bottom: 0;
    -webkit-transition: border-top 0.3s ease-out, padding-top 0.3s ease-out,
        padding-bottom 0.3s ease-out;
    -o-transition: border-top 0.3s ease-out, padding-top 0.3s ease-out,
        padding-bottom 0.3s ease-out;
    transition: border-top 0.3s ease-out, padding-top 0.3s ease-out,
        padding-bottom 0.3s ease-out;
}

.bl_gnavChildNav_link {
    font-size: 2rem;
    display: block;
    background: url(../Images/icon_listmark.svg) 83px center no-repeat;
    line-height: 0;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: line-height 0.3s ease-out, opacity 0.1s linear,
        visibility 0.1s linear;
    -o-transition: line-height 0.3s ease-out, opacity 0.1s linear,
        visibility 0.1s linear;
    transition: line-height 0.3s ease-out, opacity 0.1s linear,
        visibility 0.1s linear;
}

.bl_gnavChildNav_link.is_current {
    background: #f8f7f8 url(../Images/icon_listmark_blue.svg) 83px center
        no-repeat;
    color: #0098ce;
}

.bl_memberNav {
    font-size: 2.2rem;
    background: #575656;
}

.bl_memberNav a {
    text-decoration: none;
    color: #bdbcbc;
}

.bl_memberNav_link {
    font-size: 2rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 75px;
    padding: 20px;
    border-bottom: 1px solid #8b8b8b;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.bl_memberNav_icon {
    display: inline-block;
    width: 36px;
    margin-right: 20px;
    text-align: center;
}

.bl_utilNav {
    font-size: 2.2rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    background: #575656;
}

.bl_utilNav a {
    text-decoration: none;
    color: #bdbcbc;
}

.bl_utilNav_item {
    font-size: 2rem;
    width: 33.33333%;
}

.bl_utilNav_item:not(:last-child) {
    border-right: 1px solid #8b8b8b;
}

.bl_utilNav_link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
    padding: 15px 0 10px;
    text-align: center;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.bl_utilNav_icon {
    display: block;
    margin-bottom: 10px;
}

/* toppage contents
===================================== */
.bl_info {
    padding-bottom: 50px;
    border-top: 4px solid #0098ce;
}
.bl_info:last-child {
    padding-bottom: 0;
}

.bl_info_header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 19px 25px 15px;
    border-bottom: 1px solid #bfbfbf;
    background: #fff;
}

.bl_info_ttl {
    font-size: 3rem;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #707070;
    font-weight: normal;
    line-height: 1.2;
}

.bl_info_ttl img {
    margin-right: 20px;
}

.bl_info_main {
    padding: 20px 65px 20px;
    background: #fff;
}
.bl_footer {
    margin: 0 5%;
    border-top: 1px solid #bfbfbf;
    padding-top: 4px;
    padding-bottom: 10px;
}
.el_osl {
    text-align: right;
    padding-bottom: 20px;
}
.el_osl a {
    color: #bfbfbf;
    text-decoration: underline;
    font-size: 1.6rem;
}
.el_osl a:hover {
    text-decoration: none;
}
.el_copyrights {
    text-align: center;
    color: #777;
}
/* main header
===================================== */
.bl_mainHeader {
    padding-top: 13px;
    padding-bottom: 8px;
    border-bottom: 1px solid #bfbfbf;
}

.bl_mainHeader_inner {
    margin: 0 5%;
}

.bl_mainHeader_linkUnit {
    margin: 0 5%;
    text-align: right;
}

.bl_mainHeader_link {
    font-size: 2rem;
    color: #707070;
}

.bl_mainHeader_link:hover {
    text-decoration: underline;
}

.bl_mainHeader_link img {
    margin-left: 10px;
    vertical-align: middle;
}

/* media
===================================== */
.bl_media {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.bl_media__bd {
    margin-bottom: 28px;
    padding-bottom: 28px;
    border-bottom: 1px solid #bfbfbf;
}

.bl_media_img {
    width: 50%;
    margin-right: 70px;
}

.bl_media_video {
    width: 50%;
    margin-right: 70px;
}

.bl_media_video_inner {
    width: auto;
    height: auto;
    max-width: 100%;
}

.bl_media_img img {
    max-width: 100%;
}

.bl_media_desc {
    width: 50%;
}

.bl_media_desc p {
    margin-bottom: 1em;
    line-height: 1.7;
}

/* section
===================================== */
.bl_sect_header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 30px;
}

.bl_sect_frame {
    background: #f9f9f9;
    border-radius: 10px;
    margin-bottom: 55px;
    padding: 55px 5.20833vw;
}
.bl_sect_frame_narrow {
    padding: 30px 5.20833vw;
}
.bl_sect_desc {
    margin-bottom: 40px;
}

.bl_sect_desc p {
    line-height: 1.7;
}

.bl_sect_desc p:not(:last-child) {
    margin-bottom: 1em;
}

.bl_sect_note p {
    line-height: 1.7;
}

.bl_sect_note p:not(:last-child) {
    margin-bottom: 1em;
}

.bl_sect_box {
    width: 100%;
    padding: 30px;
    background: #fff;
}

/* side close button
===================================== */
.bl_sideCloseBtn_wrapper {
    margin: 15px 15px 15px auto;
    text-align: right;
}

.bl_sideCloseBtn {
    padding: 0;
    line-height: 0;
    border-radius: 0;
    cursor: pointer;
}

.bl_sideOpenBtn_wrapper {
    position: absolute;
    top: 71px;
    left: -9px;
    z-index: 1;
}

/* side open button
===================================== */
.bl_sideOpenBtn {
    padding: 0;
    line-height: 0;
    border-radius: 0;
    cursor: pointer;
    filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feGaussianBlur in="SourceAlpha" stdDeviation="2" /><feOffset dx="2" dy="3" result="offsetblur" /><feFlood flood-color="rgba(0,0,0,0.16)" /><feComposite in2="offsetblur" operator="in" /><feMerge><feMergeNode /><feMergeNode in="SourceGraphic" /></feMerge></filter></svg>#filter');
    -webkit-filter: drop-shadow(1px 2px 2px rgba(0, 0, 0, 0.16));
    filter: drop-shadow(1px 2px 2px rgba(0, 0, 0, 0.16));
}

/* page up/down button
===================================== */
#btn_pud {
    width: 10%;
    max-width: 50px;
    min-width: 25px;
    position: fixed;
    bottom: 42%; /* 基準の位置を画面の一番下に指定する */
    right: 10px; /* 基準の位置を画面の一番右に指定する */
    z-index: 1;
}
.btn_pud-in:first-child {
    margin-bottom: 10px;
}
.btn_pud-in {
    position: relative;
    overflow: hidden;
    width: 100%;
    border: 1px solid #0098ce; /* ボーダーを指定する */
    background-color: #fff;
}
.btn_pud-in:before {
    content: "";
    display: block;
    padding-top: 100%;
}
#pup,
#pdwn {
    display: contents;
}
#pup img,
#pdwn img {
    position: absolute;
    width: 80%;
    height: 80%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
}

/* sort
===================================== */
.bl_sortBox {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-left: auto;
}

.bl_sortBox .el_select {
    font-size: 2rem;
}

.bl_sortBox_ttl {
    margin-right: 15px;
    font-weight: bold;
}

.bl_sortBoxUnit {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 5px;
}
.bl_sortBox.bl_sortBox__l {
    margin-left: 0;
}
/* step bar
===================================== */
.bl_stepbarUnit {
    margin-bottom: 30px;
}

.bl_stepbar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    min-height: 63px;
}

.bl_stepbar_item {
    -ms-flex-negative: 1;
    flex-shrink: 1;
    position: relative;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-top: 1px solid #0098ce;
    border-bottom: 1px solid #0098ce;
    line-height: 1.2;
}

.bl_stepbar_item:first-child {
    border-left: 1px solid #0098ce;
}

.bl_stepbar_item:not(:first-child):before {
    position: absolute;
    display: block;
    content: "";
    top: -1px;
    left: -1px;
    width: 27px;
    height: calc(100% + 2px);
    background: url(../Images/img_step_white.png) top left no-repeat;
    background-size: 100% 100%;
}

.bl_stepbar_item.is_current {
    background: #0098ce;
    color: #fff;
}

.bl_stepbar_item.is_current:first-child:before {
    display: none;
}

.bl_stepbar_item.is_current:last-child:after {
    display: none;
}

.bl_stepbar_item.is_current:before {
    position: absolute;
    display: block;
    content: "";
    top: -1px;
    left: 0;
    width: 27px;
    height: calc(100% + 2px);
    background: url(../Images/img_step_white02.png) top left no-repeat;
    background-size: 100% 100%;
}

.bl_stepbar_item.is_current:after {
    position: absolute;
    display: block;
    content: "";
    top: -0.5px;
    right: -26px;
    width: 27px;
    height: 100%;
    background: url(../Images/img_step_blue.png) top left no-repeat;
    background-size: 100% calc(100% + 1px);
    z-index: 1;
}

.bl_stepbar_item.is_disabled {
    background: #f0f0f0;
    border-top: 1px solid #bfbfbf;
    border-bottom: 1px solid #bfbfbf;
}

.bl_stepbar_item.is_disabled:last-child {
    border-right: 1px solid #bfbfbf;
}

.bl_stepbar_item.is_disabled:before {
    position: absolute;
    display: block;
    content: "";
    top: -1px;
    left: -2px;
    width: 27px;
    height: calc(100% + 2px);
    background: url(../Images/img_step_gray.png) top left no-repeat;
    background-size: 100% 100%;
}

.bl_stepbar_item span {
    padding-left: 27px;
}

.bl_stepbar_item.is_current + .bl_stepbar_item.is_disabled:before {
    background: none;
}

.bl_stepbar_link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-left: 27px;
    color: #0098ce;
}

/* table 水平
===================================== */
.bl_horizTable {
    margin-bottom: 20px;
    width: 100%;
}

.bl_horizTable > tbody > tr,
.bl_horizTable > thead > tr,
.bl_horizTable > tfoot > tr {
    border-bottom: 1px solid #bfbfbf;
}

.bl_horizTable > tbody > tr > th,
.bl_horizTable > thead > tr > th,
.bl_horizTable > tfoot > tr > th {
    font-size: 2.2rem;
    width: 16vw;
    padding: 15px;
    background: #0098ce;
    font-weight: 500;
    color: #fff;
    line-height: 1.3;
    vertical-align: middle;
    text-align: left;
}

.bl_horizTable > tbody > tr > th .el_badge__required,
.bl_horizTable > thead > tr > th .el_badge__required,
.bl_horizTable > tfoot > tr > th .el_badge__required {
    margin-bottom: 5px;
}

.bl_horizTable > tbody > tr > td,
.bl_horizTable > thead > tr > td,
.bl_horizTable > tfoot > tr > td {
    padding: 15px;
    background: #fff;
    line-height: 1.3;
    vertical-align: middle;
}

.bl_horizTable02 {
    width: 100%;
    border: 1px solid #bfbfbf;
}

.bl_horizTable02 > tbody > tr,
.bl_horizTable02 > thead > tr,
.bl_horizTable02 > tfoot > tr {
    border-bottom: 1px solid #bfbfbf;
}

.bl_horizTable02 > tbody > tr > th,
.bl_horizTable02 > thead > tr > th,
.bl_horizTable02 > tfoot > tr > th {
    font-size: 2.2rem;
    width: 13.02083vw;
    padding: 15px 20px;
    background: #0098ce;
    font-weight: 500;
    color: #fff;
    line-height: 1.8;
    vertical-align: middle;
    text-align: left;
}

.bl_horizTable02 > tbody > tr > th .el_badge__required,
.bl_horizTable02 > thead > tr > th .el_badge__required,
.bl_horizTable02 > tfoot > tr > th .el_badge__required {
    margin-bottom: 5px;
}

.bl_horizTable02 > tbody > tr > td,
.bl_horizTable02 > thead > tr > td,
.bl_horizTable02 > tfoot > tr > td {
    font-size: 2.2rem;
    padding: 15px 40px;
    background: #fff;
    line-height: 1.8;
    vertical-align: middle;
}

/* table 垂直
===================================== */
.bl_vertTable {
    table-layout: fixed;
    min-width: 100%;
    border: 1px solid #bfbfbf;
    line-height: 1.3;
}

.bl_vertTable > thead > tr > th,
.bl_vertTable > tbody > tr > th,
.bl_vertTable > tfoot > tr > th {
    padding: 21px 5px;
    background: #0098ce;
    border: 1px solid #bfbfbf;
    border-top: 1px solid #0098ce;
    vertical-align: middle;
    font-weight: 500;
    color: #fff;
}

.bl_vertTable > thead > tr > th:first-child,
.bl_vertTable > tbody > tr > th:first-child,
.bl_vertTable > tfoot > tr > th:first-child {
    border-left: 1px solid #0098ce;
}

.bl_vertTable > thead > tr > th:last-child,
.bl_vertTable > tbody > tr > th:last-child,
.bl_vertTable > tfoot > tr > th:last-child {
    border-right: 1px solid #0098ce;
}

.bl_vertTable > thead > tr > td,
.bl_vertTable > tbody > tr > td,
.bl_vertTable > tfoot > tr > td {
    padding: 18px 5px 17px;
    border: 1px solid #bfbfbf;
    text-align: center;
    vertical-align: middle;
}

.bl_vertTable:not(:last-child) {
    margin-bottom: 20px;
}

/* table multi-column
===================================== */
.bl_multiColTable {
    min-width: 100%;
    border: 1px solid #bfbfbf;
    line-height: 1.3;
}

.bl_multiColTable > thead > tr > th,
.bl_multiColTable > tbody > tr > th,
.bl_multiColTable > tfoot > tr > th {
    padding: 21px 5px;
    background: #0098ce;
    border: 1px solid #bfbfbf;
    border-top: 1px solid #0098ce;
    vertical-align: middle;
    font-weight: 500;
    color: #fff;
}

.bl_multiColTable > thead > tr > th:first-child,
.bl_multiColTable > tbody > tr > th:first-child,
.bl_multiColTable > tfoot > tr > th:first-child {
    border-left: 1px solid #0098ce;
}

.bl_multiColTable > thead > tr > th:last-child,
.bl_multiColTable > tbody > tr > th:last-child,
.bl_multiColTable > tfoot > tr > th:last-child {
    border-right: 1px solid #0098ce;
}

.bl_multiColTable > thead > tr > td,
.bl_multiColTable > tbody > tr > td,
.bl_multiColTable > tfoot > tr > td {
    padding: 18px 5px 17px;
    border: 1px solid #bfbfbf;
    text-align: center;
    vertical-align: middle;
}

.bl_multiColTable > thead > tr.is_disabled th,
.bl_multiColTable > thead > tr.is_disabled td,
.bl_multiColTable > tbody > tr.is_disabled th,
.bl_multiColTable > tbody > tr.is_disabled td,
.bl_multiColTable > tfoot > tr.is_disabled th,
.bl_multiColTable > tfoot > tr.is_disabled td {
    color: #bfbfbf;
}

.bl_multiColTable > thead > tr.is_disabled th .el_link,
.bl_multiColTable > thead > tr.is_disabled td .el_link,
.bl_multiColTable > tbody > tr.is_disabled th .el_link,
.bl_multiColTable > tbody > tr.is_disabled td .el_link,
.bl_multiColTable > tfoot > tr.is_disabled th .el_link,
.bl_multiColTable > tfoot > tr.is_disabled td .el_link {
    color: #bfbfbf;
    text-decoration: none;
    pointer-events: none;
}

.bl_multiColTable:not(:last-child) {
    margin-bottom: 20px;
}

/* table 横スクロール用
===================================== */
.bl_scrollTable {
    display: block;
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
}

.bl_scrollTable .bl_vertTable {
    width: auto;
    min-width: 100%;
}

/* tab
===================================== */
.bl_tabs {
    margin-top: 70px;
}

.bl_tabs_list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    width: 100%;
    margin-bottom: 60px;
    padding: 0;
}

.bl_tabs_list li {
    font-size: 1.8rem;
    width: 100%;
    max-width: 368px;
    margin-right: 18px;
    margin-left: 18px;
    background: #fff;
    border-radius: 5px 5px 0 0;
    border-bottom: none;
    text-align: center;
}

.bl_tabs_link {
    font-size: 2.8rem;
    display: inline-block;
    width: 100%;
    padding: 20px 10px;
    background: #fff;
    border: 3px solid #0098ce;
    border-radius: 10px;
    color: #0098ce;
    text-align: center;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    line-height: 1.3;
    -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.bl_tabs_link.is_current {
    pointer-events: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.bl_tabs_link.is_current {
    background: #0098ce;
    color: #fff;
}

.bl_tabs_sect {
    margin-bottom: 60px;
}

.bl_tabs_ttl {
    font-size: 2.4rem;
    position: relative;
    margin-bottom: 50px;
    padding: 18px 20px;
    border: 2px solid #0098ce;
    border-radius: 4px;
    color: #0098ce;
    text-align: center;
}

.bl_tabs_ttl:after,
.bl_tabs_ttl:before {
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    pointer-events: none;
    position: absolute;
    top: 100%;
    left: 50%;
}

.bl_tabs_ttl:after {
    border-color: #ffffff;
    border-color: rgba(255, 255, 255, 0);
    border-top-width: 14px;
    border-bottom-width: 14px;
    border-left-width: 15px;
    border-right-width: 15px;
    margin-left: -15px;
    margin-top: -1px;
    border-top-color: #fff;
}

.bl_tabs_ttl:before {
    border-color: #1e90ff;
    border-color: rgba(30, 144, 255, 0);
    border-top-width: 14px;
    border-bottom-width: 14px;
    border-left-width: 16px;
    border-right-width: 16px;
    margin-left: -16px;
    margin-top: 1px;
    border-top-color: #0098ce;
}

.bl_multiTabs {
    margin-bottom: 5%;
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16);
}
.bl_multiTabs .bl_stepbar_item.is_current:before,
.bl_multiTabs .bl_stepbar_item.is_current:after,
.bl_multiTabs .bl_stepbar_item:not(:first-child):before,
.bl_multiTabs .bl_stepbar_link:hover:after {
    content: none !important;
}

.bl_multiTabs .bl_stepbar_item span {
    padding-left: 0;
}
.bl_multiTabs .bl_stepbar_link {
    padding-left: 0;
}
.bl_multiTabs .bl_stepbar {
    border: 1px solid #0098ce;
}
.bl_multiTabs .bl_stepbar_item {
    border-top: none;
    border-bottom: none;
    border-right: 1px solid #0098ce;
}
.bl_multiTabs .bl_stepbar_item:hover {
    border-right: 1px solid #fff;
}
.bl_multiTabs .bl_stepbar_item:first-child {
    border-left: none;
}
.bl_multiTabs .bl_stepbar_item.is_current {
    border-right: 1px solid #fff;
}
.bl_multiTabs .bl_stepbar_item:last-child {
    border-right: none;
}
/* todo list
===================================== */
.bl_todo_item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 30px;
    padding: 20px 70px 20px 45px;
    background-color: #bfbfbf;
    background-color: rgba(191, 191, 191, 0.26);
    line-height: 1.6;
}

.bl_todo_item:last-child {
    margin-bottom: 0;
}

.bl_todo_item.is_error {
    background: #fdc9c0 url(../Images/icon_erro_rd03.svg) calc(100% - 30px)
        center no-repeat;
    background: rgba(253, 201, 192, 0.26) url(../Images/icon_erro_rd03.svg)
        calc(100% - 30px) center no-repeat;
}

.bl_todo_item.is_error .bl_todo_txt {
    color: #fd5618;
}

.bl_todo_item .el_label {
    margin-top: 10px;
}

.bl_todo_main {
    margin-left: 60px;
}

.bl_todo_date {
    font-size: 2rem;
    display: inline-block;
    color: #707070;
}

.bl_todo_txt {
    font-size: 2.2rem;
    line-height: 1.5;
    font-weight: bold;
}

/* image upload
===================================== */
.bl_upload {
    width: 100%;
    max-width: 520px;
    margin: 30px auto 50px;
}
.bl_upload_url {
    width: 100%;
    margin: 30px auto 50px;
}

.bl_upload_ttl {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 25px;
    padding: 10px 20px 10px 35px;
    background: #707070;
    border-radius: 999em;
    font-weight: bold;
    color: #fff;
    line-height: 1;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.bl_upload_del {
    color: #bfbfbf;
}

.bl_upload_del img {
    margin-left: 3px;
    vertical-align: bottom;
}

.bl_upload_box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    margin-bottom: 25px;
}

.bl_upload_desc {
    margin-bottom: 8px;
    font-weight: bold;
}

.bl_upload_img {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-right: 20px;
    width: 60%;
    max-width: 303px;
}

.bl_upload_img img {
    max-width: 100%;
}

/* お知らせ
===================================== */
.bl_vertPosts_item {
    margin-bottom: 15px;
    line-height: 1.6;
}

.bl_vertPosts_date {
    float: left;
    font-size: 2rem;
    display: inline-block;
    color: #777;
}

.bl_vertPosts_ttl {
    padding-left: 12rem;
    font-size: 2.2rem;
    margin-bottom: 10px;
    line-height: 1.5;
}

.bl_vertPosts_link {
    color: #0098ce;
    text-decoration: underline;
}

.el_note_sm {
    font-size: 1.6rem;
}
.el_vertPosts_mark {
    float: left;
    font-size: 2rem;
    display: inline-block;
    margin-top: 4px;
    margin-right: 1rem;
    padding: 3px 5px;
    border-radius: 5px;
    font-weight: normal;
    line-height: 1;
}
.bl_vertPosts_todo {
    padding-left: 18rem;
}
.el_vertPosts_mark__unread {
    background: #fd5618;
    color: #fff;
}
.el_vertPosts_mark__read {
    background: #9c9c9c;
    color: #fff;
}
/* badge
===================================== */
.el_badge {
    font-size: 2rem;
    display: inline-block;
    padding: 3px 5px;
    border-radius: 5px;
    font-weight: normal;
    line-height: 1;
}

/* 必須 */
.el_badge__required {
    background: #fd5618;
    color: #fff;
}
.el_badge__register {
    background: #f59511;
    color: #fff;
}

/* ====================================
  element
==================================== */
/* button
===================================== */
.el_submitBtn {
    font-size: 2.8rem;
    display: inline-block;
    width: 100%;
    max-width: 250px;
    padding: 10px 10px 8px;
    border: 2px solid #0098ce;
    border-radius: 7px;
    background: #0098ce;
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16);
    color: #fff;
    text-align: center;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    line-height: 1.3;
    cursor: pointer;
}

.el_submitBtn__calc {
    font-size: 2.4rem;
    max-width: 400px;
}

.el_submitBtn__calc small {
    font-size: 2rem;
}

.el_submitBtn__plus {
    background: #0098ce url(../Images/icon_plus.svg) calc(100% - 15px) center
        no-repeat;
}

.el_actBtn {
    font-size: 2rem;
    display: inline-block;
    padding: 6px 1.04167vw 4px;
    border: 2px solid #f59511;
    border-radius: 99em;
    background: #f59511;
    color: #fff;
    text-align: center;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    line-height: 1.3;
    white-space: nowrap;
    cursor: pointer;
}

/* 戻る button
===================================== */
.el_backBtn {
    font-size: 2.8rem;
    display: inline-block;
    width: 100%;
    max-width: 250px;
    padding: 10px 10px 8px;
    border: 2px solid #0098ce;
    border-radius: 7px;
    color: #0098ce;
    text-align: center;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    line-height: 1.3;
    cursor: pointer;
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}
.bl_horizBtnUnit .el_backBtn {
    max-width: 250px;
}
.bl_horizBtnUnit__blue > .el_backBtn {
    background: #fff url(../Images/icon_arrow_back.svg) 20px center no-repeat;
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}
/* 次へ button
===================================== */
.el_nextBtn {
    font-size: 2.8rem;
    display: inline-block;
    width: 100%;
    max-width: 250px;
    padding: 10px 10px 8px;
    border: 2px solid #0098ce;
    border-radius: 7px;
    color: #0098ce;
    text-align: center;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    line-height: 1.3;
    cursor: pointer;
    background: #fff url(../Images/icon_arrow_next.svg) calc(100% - 20px) center
        no-repeat;
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}

/* 保存 button
===================================== */
.el_saveBtn {
    font-size: 2.8rem;
    display: inline-block;
    width: 100%;
    max-width: 250px;
    padding: 10px 20px 8px 10px;
    border: 2px solid #f59511;
    border-radius: 7px;
    color: #fff;
    text-align: center;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    line-height: 1.3;
    cursor: pointer;
    background: #f59511 url(../Images/icon_save.svg) calc(100% - 20px) center
        no-repeat;
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}

/* - + button
===================================== */
.el_funcBtn {
    font-size: 2rem;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.el_funcBtn:hover {
    opacity: 0.7;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.el_funcBtn img {
    margin-left: 5px;
    line-height: 0;
}

/* close button
===================================== */
.el_closeBtn {
    font-size: 2rem;
    display: inline-block;
    padding: 6px 56px 4px 35px;
    border-radius: 999em;
    background: url(../Images/icon_close_up_blue.svg) calc(100% - 22px) center
        no-repeat;
    border: 2px solid #0098ce;
    color: #0098ce;
    text-align: center;
    line-height: 1.3;
    cursor: pointer;
}
.el_openBtn {
    font-size: 2rem;
    display: inline-block;
    padding: 6px 56px 4px 35px;
    border-radius: 999em;
    background: url(../Images/icon_open_dwn_blue.svg) calc(100% - 22px) center
        no-repeat;
    border: 2px solid #0098ce;
    color: #0098ce;
    text-align: center;
    line-height: 1.3;
    cursor: pointer;
}
.el_openBtn__close {
    background: url(../Images/icon_close_up_blue.svg) calc(100% - 22px) center
        no-repeat;
}

/* 汎用 button
===================================== */
/* large orange
===================================== */
.el_btn {
    font-size: 2.8rem;
    display: inline-block;
    width: 100%;
    max-width: 250px;
    padding: 10px 10px 8px;
    border: 2px solid #f59511;
    border-radius: 7px;
    color: #f59511;
    text-align: center;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    line-height: 1.3;
    cursor: pointer;
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
    background-color: #fff;
}

.el_btn.is_disabled {
    border: 2px solid #bfbfbf;
    color: #bfbfbf;
    pointer-events: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.el_btn__bg {
    background: #f59511;
    color: #fff;
}

.el_btn__clear {
    background: #fff;
    border: 2px solid #fff;
    color: #f59511;
}
.bl_horizBtnUnit .el_btn {
    max-width: 250px;
}
.bl_horizBtnUnit__blue .el_btn {
    background-color: #fff;
}
/* large blue button
===================================== */
.el_blueBtn {
    font-size: 2.8rem;
    display: inline-block;
    width: 100%;
    max-width: 250px;
    padding: 10px 10px 8px;
    background: #fff;
    border: 2px solid #0098ce;
    border-radius: 7px;
    color: #0098ce;
    text-align: center;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    line-height: 1.3;
    cursor: pointer;
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}

.el_blueBtn.is_disabled {
    border: 2px solid #bfbfbf;
    color: #bfbfbf;
    pointer-events: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.el_blueBtn__bg {
    background: #0098ce;
    color: #fff;
}

.el_blueBtn__clear {
    background: #fff;
    border: 2px solid #fff;
    color: #0098ce;
}

/* small orange button
===================================== */
.el_smBtn {
    font-size: 2rem;
    display: inline-block;
    width: 100%;
    max-width: 202px;
    padding: 5px 20px;
    border-radius: 999em;
    background: #fff;
    border: 2px solid #f59511;
    color: #f59511;
    text-align: center;
    line-height: 1.3;
    cursor: pointer;
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}

.el_smBtn__inline {
    width: auto;
    min-width: auto;
    padding-right: 30px;
    padding-left: 30px;
}

.el_smBtn__bg {
    background: #f59511;
    color: #fff;
}

.el_smBtn__clear {
    background: #fff;
    border: 2px solid #fff;
    color: #f59511;
}

.el_smBtn.is_disabled {
    border: 2px solid #bfbfbf;
    color: #bfbfbf;
    pointer-events: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.el_smBtn.is_selected {
    border-color: #fff;
    background: #fff;
    color: #f59511;
}

/* small blue button
===================================== */
.el_smBlueBtn {
    font-size: 2rem;
    display: inline-block;
    width: 100%;
    max-width: 202px;
    padding: 5px 20px 5px;
    border-radius: 999em;
    background: #fff;
    border: 2px solid #0098ce;
    color: #0098ce;
    text-align: center;
    line-height: 1.3;
    cursor: pointer;
}

.el_smBlueBtn__bg {
    background: #0098ce;
    color: #fff;
}

.el_smBlueBtn__clear {
    background: #fff;
    border: 2px solid #fff;
    color: #0098ce;
}

.el_smBlueBtn.is_disabled {
    border: 2px solid #bfbfbf;
    color: #bfbfbf;
    pointer-events: none;
}

.el_smBlueBtn.is_selected {
    border-color: #fff;
    background: #fff;
    color: #0098ce;
}

/* shadow
===================================== */
.el_shadowBtn {
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}

/* icon button
===================================== */
.el_iconBtn {
    font-size: 2rem;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 3px 24px;
    border-radius: 999em;
    background: #fff;
    border: 2px solid #f59511;
    color: #f59511;
    text-align: center;
    line-height: 1.3;
    cursor: pointer;
}

.el_iconBtn span {
    padding: 2px 0 1px;
}

.el_iconBtn img {
    margin-left: 8px;
}

.el_iconBlueBtn {
    font-size: 2rem;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 3px 24px;
    border-radius: 999em;
    background: #fff;
    border: 2px solid #0098ce;
    color: #0098ce;
    text-align: center;
    line-height: 1.3;
    cursor: pointer;
}

.el_iconBlueBtn span {
    padding: 2px 0 1px;
}

.el_iconBlueBtn img {
    margin-left: 8px;
}

/* error
===================================== */
.el_error {
    font-size: 2rem;
    color: #fd5618;
    line-height: 1.4;
}

.el_error__org {
    color: #f59511;
}

.el_errorIcon {
    width: 25px;
}

/* error area
===================================== */
#errorarea {
    border: solid 1px #fd5618;
    border-radius: 5px;
    padding: 10px 20px;
    margin-bottom: 3vw;
    background-color: rgba(253, 201, 192, 0.26);
    color: #fd5618;
}
#errorarea li,
#errorarea p {
    background-image: url(../Images/icon_erro_rd03.svg);
    background-repeat: no-repeat;
    background-position-y: 0.6em;
    background-size: 2rem;
    padding-left: 3rem;
    line-height: 2;
}
#errorarea > ul > li.el_noMark {
    background-image: none;
    padding-left: 0;
}

/* message
===================================== */
.el_messageIcon {
    width: 25px;
}

/* form input
===================================== */
.el_input {
    font-size: 2.2rem;
    width: 80%;
    max-width: 340px;
    height: 50px;
    margin: 5px 0;
    padding: 7px 15px 6px;
    background: #fff;
    border: 1px solid #bfbfbf;
    border-radius: 5px;
    color: #454545;
    line-height: 1.6;
}

.el_input:placeholder-shown {
    color: #bfbfbf;
}

.el_input::-webkit-input-placeholder {
    color: #bfbfbf;
}

.el_input:-moz-placeholder {
    color: #bfbfbf;
    opacity: 1;
}

.el_input::-moz-placeholder {
    color: #bfbfbf;
    opacity: 1;
}

.el_input:-ms-input-placeholder {
    color: #bfbfbf;
}

.el_textarea:placeholder-shown {
    color: #bfbfbf;
}

.el_textarea::-webkit-input-placeholder {
    color: #bfbfbf;
}

.el_textarea:-moz-placeholder {
    color: #bfbfbf;
    opacity: 1;
}

.el_textarea::-moz-placeholder {
    color: #bfbfbf;
    opacity: 1;
}

.el_textarea:-ms-input-placeholder {
    color: #bfbfbf;
}

.el_input__sm {
    max-width: 160px;
}

.el_input__md {
    max-width: 220px;
}
.el_input__lg {
    max-width: 290px;
}
.el_input__max {
    width: 100%;
    max-width: 100%;
}
.el_input__65 {
    width: 65%;
    max-width: 65%;
}
.el_input__datepicker {
    max-width: 200px;
    background: url(../Images/icon_datepicker.svg) calc(100% - 10px) center
        no-repeat;
}

.el_input__num {
    font-size: 2.4rem;
    max-width: 130px;
    font-weight: 600;
    text-align: right;
}

.el_input__numSm {
    font-size: 2.4rem;
    max-width: 120px;
    font-weight: 600;
    text-align: right;
}

.el_input.is_error {
    background: #fce7e7;
    border: 1px solid #e35353;
}

.el_input.is_disabled {
    background-color: #ddd;
    border-color: #bfbfbf;
    color: #454545;
    opacity: 1 !important;
}
.el_input__80 {
    max-width: 80%;
}
.el_input__red {
    color: #eb5454;
}
.el_input__time {
    width: 16rem !important;
}
/* form textarea
===================================== */
.el_textarea {
    font-size: 2.2rem;
    width: 100%;
    padding: 7px 15px 6px;
    border: 1px solid #bfbfbf;
    border-radius: 5px;
    color: #333;
    line-height: 1.6;
}

.el_textarea_urlcomment {
    width: 50%;
}

.el_textarea.is_error {
    border: 1px solid #e35353;
}

textarea.wysiwyg {
    font-size: 1.6rem;
    width: 100%;
    max-width: 1000px;
    padding: 9px 10px;
    background: #fcfcfc;
    border: 1px solid #bfbfbf;
    color: #333;
    line-height: 1.6;
    color: #454545;
}
.el_textarea.is_disabled {
    background-color: #ddd;
    border-color: #bfbfbf;
    color: #454545;
    opacity: 1 !important;
}
/* form checkbox
===================================== */
.el_checkbox {
    filter: alpha(opacity=0);
    opacity: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: absolute;
}

.el_checkbox.is_error + .el_checkbox_txt:before {
    border: 2px solid #fd5618 !important;
}

.el_checkbox_txt {
    font-size: 2rem;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: auto;
    padding-left: 30px;
    padding-bottom: 3px;
    line-height: 1.4;
    cursor: pointer;
}

.el_checkbox_txt__solo {
    width: 20px;
    height: 20px;
    padding: 0;
}

.el_label:hover .el_checkbox_txt {
    color: #001e29;
}

.el_label:hover .el_checkbox_txt:before {
    border: 1px solid #001e29 !important;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.el_label:hover .el_checkbox:checked + .el_checkbox_txt:after {
    border-bottom: 3px solid #001e29;
    border-right: 3px solid #001e29;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.el_label.is_disabled:hover {
    pointer-events: none !important;
}

.el_checkbox_txt:before {
    content: "";
    display: block;
    position: absolute;
    top: 4px;
    left: 0;
    width: 18px;
    height: 18px;
    background: #fff;
    border: 1px solid #bfbfbf;
}
.el_checkbox_txt__solo::before {
    top: 0px;
    left: 0px;
}

.el_checkbox_txt:before:hover {
    border: 1px solid #001e29 !important;
}

.el_checkbox:checked + .el_checkbox_txt:after {
    content: "";
    display: block;
    position: absolute;
    top: 2px;
    left: 5px;
    width: 8px;
    height: 17px;
    -webkit-transform: rotate(40deg);
    -ms-transform: rotate(40deg);
    transform: rotate(40deg);
    border-bottom: 3px solid #707070;
    border-right: 3px solid #707070;
}
.el_checkbox:checked + .el_checkbox_txt__solo:after {
    top: -2px;
}

.el_checkbox:checked + .el_checkbox_txt:before {
    content: "";
    display: block;
    position: absolute;
    top: 4px;
    left: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #707070;
}

.el_checkbox:checked + .el_checkbox_txt__solo:before {
    top: 0px;
    left: 0px;
}

.el_checkbox:focus + .el_checkbox_txt {
    outline: 1px solid #82c7fa;
}

.el_checkbox:disabled + .el_checkbox_txt {
    color: #ddd;
    pointer-events: none;
}

.el_checkbox:disabled + .el_checkbox_txt:before {
    border: 2px solid #ddd;
    background: #fff;
    pointer-events: none;
}

.el_checkbox:disabled + .el_checkbox_txt:after {
    border-bottom: 3px solid #ddd;
    border-right: 3px solid #ddd;
    pointer-events: none;
}

/* form select
===================================== */
.el_select {
    font-size: 2.2rem;
    display: inline-block;
    width: 70%;
    max-width: 340px;
    margin: 5px 0;
    padding: 10px 46px 8px 15px;
    line-height: 1.4;
    border: 1px solid #bfbfbf;
    border-radius: 5px;
    background: #fff url(../Images/arrow_down.svg) calc(100% - 10px) center
        no-repeat;
    color: #454545;
    cursor: pointer;
}

.el_select__auto {
    width: auto;
}

.el_select.is_error {
    border: 1px solid #e35353;
}

.el_select.is_disabled {
    background-color: #ddd;
    color: #454545;
    opacity: 1;
    cursor: default;
}

.el_select:focus {
    outline: 1px solid #82c7fa;
}

.el_select::-ms-expand {
    display: none;
}

.el_select[aria-selected="true"]:before {
    color: #086ed4;
    content: "";
    left: 16px;
    position: absolute;
    top: 8px;
}
.el_select_pref {
    width: 200px;
}
.el_input__countryCode {
    margin-right: 10px;
    width: 80px;
}
.el_input__phoneNum {
    width: 100px;
}
.el_select__max {
    width: 100%;
    max-width: 100%;
}
.el_select__w80per {
    width: 80%;
}
/* 見出し
===================================== */
.el_lv1Heading {
    font-size: 4.6rem;
    width: 100%;
    font-weight: normal;
    line-height: 1.2;
}

.el_lv2Heading {
    font-size: 3.3rem;
    width: 100%;
    font-weight: normal;
    line-height: 1.2;
}

.el_lv2Heading:before {
    display: inline-block;
    width: 8px;
    height: 100%;
    margin-right: 18px;
    background: #0098ce;
    border-radius: 10px;
    content: "　";
}

.el_lv3Heading {
    font-size: 2.8rem;
    width: 100%;
    padding-bottom: 15px;
    border-bottom: 1px solid #0098ce;
    font-weight: normal;
    line-height: 1.2;
}

.el_lv3Heading:before {
    position: absolute;
    display: inline-block;
    width: 8px;
    height: auto;
    margin-right: 18px;
    background: #0098ce;
    border-radius: 10px;
    content: "　";
}
.el_lv3Heading span {
    display: block;
    padding-left: 3rem;
}

/* link text
===================================== */
.el_link {
    color: #0098ce;
    text-decoration: underline;
}
.el_link.el_link__black {
    color: #454545;
}
.el_link.el_link__red {
    color: #fd5618;
}

/* loader
===================================== */
.el_loader {
    position: relative;
}

.el_loader_body,
.el_loader_body:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
}

.el_loader_body {
    font-size: 1.4rem;
    margin: 0 auto;
    border-top: 0.4em solid rgba(112, 112, 112, 0.2);
    border-right: 0.4em solid rgba(112, 112, 112, 0.2);
    border-bottom: 0.4em solid rgba(112, 112, 112, 0.2);
    border-left: 0.4em solid #707070;
    -webkit-animation: load8 2.1s infinite linear;
    animation: load8 2.1s infinite linear;
}

.el_loader_txt {
    font-size: 2.3rem;
    position: absolute;
    bottom: 50%;
    right: 50%;
    -webkit-transform: translate(50%, 50%);
    -ms-transform: translate(50%, 50%);
    transform: translate(50%, 50%);
    color: #bfbfbf;
}

@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
.el_form_txt {
    margin-left: 10px;
}
/* ==========================================
  unique
========================================== */
/* top content only
===================================== */
.un_topMainCont {
    margin: 0 5%;
    padding-top: 15px;
}

/* 次へボタン画像
===================================== */
.un_nextBtnImg {
    display: inline-block;
    margin-right: 5px;
    vertical-align: middle;
}

/* ====================================
  余白調整用
==================================== */
.hp_mt0 {
    margin-top: 0px !important;
}
.hp_mb0 {
    margin-bottom: 0px !important;
}
.hp_pt0 {
    padding-top: 0px !important;
}
.hp_pb0 {
    padding-bottom: 0px !important;
}
.hp_mt5 {
    margin-top: 5px !important;
}
.hp_mb5 {
    margin-bottom: 5px !important;
}
.hp_pt5 {
    padding-top: 5px !important;
}
.hp_pb5 {
    padding-bottom: 5px !important;
}
.hp_mt10 {
    margin-top: 10px !important;
}
.hp_mb10 {
    margin-bottom: 10px !important;
}
.hp_pt10 {
    padding-top: 10px !important;
}
.hp_pb10 {
    padding-bottom: 10px !important;
}
.hp_mt15 {
    margin-top: 15px !important;
}
.hp_mb15 {
    margin-bottom: 15px !important;
}
.hp_pt15 {
    padding-top: 15px !important;
}
.hp_pb15 {
    padding-bottom: 15px !important;
}
.hp_mt20 {
    margin-top: 20px !important;
}
.hp_mb20 {
    margin-bottom: 20px !important;
}
.hp_pt20 {
    padding-top: 20px !important;
}
.hp_pb20 {
    padding-bottom: 20px !important;
}
.hp_mt25 {
    margin-top: 25px !important;
}
.hp_mb25 {
    margin-bottom: 25px !important;
}
.hp_pt25 {
    padding-top: 25px !important;
}
.hp_pb25 {
    padding-bottom: 25px !important;
}
.hp_mt30 {
    margin-top: 30px !important;
}
.hp_mb30 {
    margin-bottom: 30px !important;
}
.hp_pt30 {
    padding-top: 30px !important;
}
.hp_pb30 {
    padding-bottom: 30px !important;
}
.hp_mt35 {
    margin-top: 35px !important;
}
.hp_mb35 {
    margin-bottom: 35px !important;
}
.hp_pt35 {
    padding-top: 35px !important;
}
.hp_pb35 {
    padding-bottom: 35px !important;
}
.hp_mt40 {
    margin-top: 40px !important;
}
.hp_mb40 {
    margin-bottom: 40px !important;
}
.hp_pt40 {
    padding-top: 40px !important;
}
.hp_pb40 {
    padding-bottom: 40px !important;
}
.hp_mt45 {
    margin-top: 45px !important;
}
.hp_mb45 {
    margin-bottom: 45px !important;
}
.hp_pt45 {
    padding-top: 45px !important;
}
.hp_pb45 {
    padding-bottom: 45px !important;
}
.hp_mt50 {
    margin-top: 50px !important;
}
.hp_mb50 {
    margin-bottom: 50px !important;
}
.hp_pt50 {
    padding-top: 50px !important;
}
.hp_pb50 {
    padding-bottom: 50px !important;
}
.hp_mt55 {
    margin-top: 55px !important;
}
.hp_mb55 {
    margin-bottom: 55px !important;
}
.hp_pt55 {
    padding-top: 55px !important;
}
.hp_pb55 {
    padding-bottom: 55px !important;
}
.hp_mt60 {
    margin-top: 60px !important;
}
.hp_mb60 {
    margin-bottom: 60px !important;
}
.hp_pt60 {
    padding-top: 60px !important;
}
.hp_pb60 {
    padding-bottom: 60px !important;
}
.hp_mt65 {
    margin-top: 65px !important;
}
.hp_mb65 {
    margin-bottom: 65px !important;
}
.hp_pt65 {
    padding-top: 65px !important;
}
.hp_pb65 {
    padding-bottom: 65px !important;
}
.hp_mt70 {
    margin-top: 70px !important;
}
.hp_mb70 {
    margin-bottom: 70px !important;
}
.hp_pt70 {
    padding-top: 70px !important;
}
.hp_pb70 {
    padding-bottom: 70px !important;
}
.hp_mt75 {
    margin-top: 75px !important;
}
.hp_mb75 {
    margin-bottom: 75px !important;
}
.hp_pt75 {
    padding-top: 75px !important;
}
.hp_pb75 {
    padding-bottom: 75px !important;
}
.hp_mr0 {
    margin-right: 0px !important;
}
.hp_ml0 {
    margin-left: 0px !important;
}
.hp_mr5 {
    margin-right: 5px !important;
}
.hp_ml5 {
    margin-left: 5px !important;
}
.hp_mr10 {
    margin-right: 10px !important;
}
.hp_ml10 {
    margin-left: 10px !important;
}
.hp_mr15 {
    margin-right: 15px !important;
}
.hp_ml15 {
    margin-left: 15px !important;
}
.hp_mr20 {
    margin-right: 20px !important;
}
.hp_ml20 {
    margin-left: 20px !important;
}
.hp_mr25 {
    margin-right: 25px !important;
}
.hp_ml25 {
    margin-left: 25px !important;
}
.hp_mlAuto {
    margin-left: auto;
}
.hp_mb1Rem {
    margin-bottom: 1rem;
}
.hp_mb5Rem {
    margin-bottom: 5rem;
}
.hp_mb7Rem {
    margin-bottom: 7rem;
}
.hp_noWrap {
    white-space: nowrap;
}
.hp_dn {
    display: none;
}
.hp_bg__gl {
    background-color: #f9f9f9 !important;
}
.hp_fontColor__green {
    color: forestgreen;
}
.mcTable_align.sticky_table tbody td {
    text-align: left;
}

/* ====================================
  width
==================================== */
.hp_wAuto {
    width: auto;
}
/* ====================================
  text-align
==================================== */
.hp_tac {
    text-align: center !important;
}
.hp_tar {
    text-align: right !important;
}
.hp_tal {
    text-align: left !important;
}

/* ====================================
  vertical-align
==================================== */
.hp_vam {
    vertical-align: middle;
}

/* ====================================
  nowrap
==================================== */
.hp_nwp {
    white-space: nowrap !important;
}

/* ====================================
  background
==================================== */
.hp_gray {
    background: #f2f2f2;
}

/* ====================================
  インターン概要
==================================== */
.bl_sect_inner_internOverView p {
    line-height: 1.5;
}
.bl_sect_list {
    margin-bottom: 4vw;
}
.bl_sect_list:last-child {
    margin-bottom: 0;
}
.el_list_blue {
    margin-bottom: 1vw;
}
.el_list_blue:last-child {
    margin-bottom: 0;
}
.el_list_blue li {
    position: relative;
    padding-left: 0.75em;
    margin-bottom: 10px;
    line-height: 1.3;
    font-size: 2rem;
    font-weight: 300;
}
.el_list_blue li:last-child {
    margin-bottom: 0;
}
.el_list_blue li:before {
    position: absolute;
    top: calc((1.3em - 10px) / 2);
    left: 0;
    content: "";
    display: block;
    background: #009ddb;
    border-radius: 50%;
    width: 8px;
    height: 8px;
}
.el_numlist li {
    position: relative;
    padding-left: 1.5em;
    margin-bottom: 10px;
    line-height: 1.5;
    font-size: 2rem;
    font-weight: 300;
    list-style: none;
    counter-increment: number;
}
.el_numlist li:before {
    content: counter(number) ".";
    position: absolute;
    left: 0;
    font-weight: 700;
}
.el_numlist .el_note {
    display: block;
    font-size: 1.6rem;
}
.el_numlist .el_comment {
    display: block;
    position: relative;
    background: #009ddb;
    margin: 25px 0 45px;
    border-radius: 5px;
    padding: 0 0.8em;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 1.8rem;
    font-weight: 400;
    color: #fff;
}
.el_numlist .el_comment:before {
    content: "";
    position: absolute;
    top: -6px;
    left: 110px;
    display: block;
    border-color: transparent transparent #009ddb;
    border-style: solid;
    border-width: 0 6px 6px;
    width: 0;
    height: 0;
}
.el_bold {
    font-weight: bold;
}

/* ====================================
  インターン案件募集要件（待遇）
==================================== */
.bl_formUnit_utilityCosts {
    padding-left: 6rem;
}
/* ====================================
  インターン案件募集要件（サポートサービス）
==================================== */
.bl_sect_list .el_aside {
    display: block;
    padding-left: 3rem;
    font-size: 2rem;
    line-height: 1.4;
}
.bl_list_spsv .el_checkbox_txt {
    font-size: 2.4rem;
}
.bl_list_spsv li {
    margin-bottom: 2vh;
    font-size: 2.4rem;
}
.bl_list_spsv li:last-child {
    margin-bottom: 0;
}
.bl_sect_list dl {
    margin-top: 1vh;
}
.bl_sect_list .el_aside dt {
    font-weight: bold;
}
.bl_sect_list .el_aside dd {
    margin-bottom: 1vh;
}
.bl_list_spsv .el_checkbox_txt:before {
    top: 7px;
}
.bl_list_spsv .el_checkbox:checked + .el_checkbox_txt:before {
    top: 7px;
}
.bl_list_spsv .el_checkbox:checked + .el_checkbox_txt:after {
    top: 5px;
}
/* ====================================
  ログイン画面
==================================== */
.ly_cont_login {
    max-width: 900px;
    margin: auto;
}
.ly_cont_login .ly_mainCont_inner {
    margin-top: 4vw;
}
.ly_cont_login .bl_formUnit {
    margin-bottom: 2vw;
}
.ly_cont_login .el_aside {
    margin: 1vw 0 2vw 0;
}
.ly_cont_login .ly_mainCont_inner {
    padding-bottom: 0;
}
/* ====================================
  案件一覧
==================================== */
.bl_horizBtnUnit__multi {
    margin-bottom: 15px;
    justify-content: flex-start;
}
.bl_horizBtnUnit__multi button {
    padding: 10px 0;
    font-size: 2.5rem;
    flex: 1;
}
.bl_horizBtnUnit__multi button:first-child {
    margin-left: 0;
}
.bl_horizBtnUnit__multi button:last-child {
    margin-right: 0;
}
.bl_sect_frame_narrow > .bl_horizTable > tbody > tr > td,
.bl_sect_frame_narrow > .bl_horizTable > thead > tr > td,
.bl_sect_frame_narrow > .bl_horizTable > tfoot > tr > td {
    padding: 5px 15px;
    font-size: 2rem;
}
.bl_sect_frame_narrow > .bl_horizTable > tbody > tr > th,
.bl_sect_frame_narrow > .bl_horizTable > thead > tr > th,
.bl_sect_frame_narrow > .bl_horizTable > tfoot > tr > th {
    padding: 5px 15px;
    font-size: 2rem;
}

/* sticky table */
.sticky_table_wrapper {
    overflow: scroll;
    width: auto;
    max-height: 75vh;
}
.sticky_table_wrapper.sticky_table_wrapperNarrow {
    max-height: 40vh;
}
.sticky_table_wrapper.sticky_table_wrapperNoScroll {
    overflow: initial;
    overflow-x: scroll;
    max-height: 100%;
}
.sticky_table_wrapper.sticky_table_wrapperNoScroll .sticky_table thead th {
    position: initial;
}

.sticky_table.sticky_table__equal {
    table-layout: fixed;
}
.sticky_table thead th {
    /* 縦スクロール時に固定する */
    position: -webkit-sticky;
    position: sticky;
    top: -1px;
    /* tbody内のセルより手前に表示する */
    z-index: 1;
}
.sticky_table thead th:first-child {
    /* 行内の他のセルより手前に表示する */
    z-index: 2;
}
.sticky_table th:first-child {
    /* 横スクロール時に固定する
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  */
}
.sticky_table thead tr:nth-child(1) th {
    top: -1px;
}
.sticky_table thead tr:nth-child(2) th {
    top: 68px; /* 2行目は1行目の高さの位置に固定する */
}
/* design */

.sticky_table {
    /*width: 1377px;*/
    width: auto;
    min-width: 100%;
    border-collapse: collapse;
    border: 1px solid #bfbfbf;
    line-height: 1.3;
}
.sticky_table th,
.sticky_table td {
    font-weight: normal;
    border: 1px solid #bfbfbf;
}
.sticky_table .el_vTitle {
    font-size: 2.2rem;
    width: 16vw;
    padding: 15px;
    background: #0098ce;
    font-weight: 500;
    color: #fff;
    line-height: 1.3;
    vertical-align: middle;
    text-align: left;
}
.sticky_table .mixTableBody {
    padding: 15px;
}
.sticky_table th::before {
    content: "";
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-bottom: 1px solid #bfbfbf;
}
.sticky_table thead th {
    /*min-width: 92px;*/
    padding: 21px 5px;
    background: #0098ce;
    border: 1px solid #bfbfbf;
    border-top: 1px solid #0098ce;
    vertical-align: middle;
    font-weight: 500;
    color: #fff;
    white-space: nowrap;
}
.sticky_table thead th:first-child {
    /*min-width: 52px;*/
}
.sticky_table tbody td {
    padding: 18px 5px 17px;
    border: 1px solid #bfbfbf;
    text-align: center;
    vertical-align: middle;
    background-color: #fff;
}
.bl_searchBtn button {
    font-size: 2.8rem;
    display: inline-block;
    width: 100%;
    max-width: 250px;
    padding: 10px 10px 8px;
    color: #0098ce;
    border: 2px solid #0098ce;
    text-align: center;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    line-height: 1.3;
    cursor: pointer;
}
.bl_sect_frame_narrow {
    border-top: 4px solid #0098ce;
    border-radius: 0 0 1.04167vw 1.04167vw;
}
.el_searchBtn__open {
    background: url(../Images/icon_search_dwn_blue.svg) calc(100% - 22px) center
        no-repeat;
    background-color: #fff;
    margin-bottom: 40px;
    border-radius: 7px;
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}
.el_searchBtn__close {
    background: url(../Images/icon_search_up_w.svg) calc(100% - 22px) center
        no-repeat;
    background-color: #0098ce !important;
    color: #fff !important;
    border-bottom: none !important;
    margin-bottom: 0px;
    border-radius: 7px 7px 0 0;
}
/* ====================================
  お知らせ詳細
==================================== */
.bl_infoBody a {
    color: #0098ce;
    text-decoration: underline;
}
.bl_infoBody a:hover {
    text-decoration: none;
}
.bl_sect_header__info {
    margin-bottom: 10px;
}
.el_date {
    margin-bottom: 20px;
}

.bl_caseInfo {
    margin-bottom: 5%;
    border: 1px solid #bfbfbf;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
}
.bl_caseInfo .bl_coLogo,
.bl_caseInfo .bl_coTxt {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}
.bl_caseInfo .bl_coLogo {
    width: 20%;
    text-align: center;
}
.bl_caseInfo .bl_coLogo p {
    padding: 10px;
}
.bl_caseInfo .bl_coLogo img {
    width: 80%;
}
.bl_caseInfo .bl_coTxt {
    width: 80%;
    border-left: 1px solid #bfbfbf;
}
.bl_caseInfo dl {
    width: 100%;
    padding: 20px;
    line-height: 1.3;
}
.bl_caseInfo dt,
.bl_caseInfo dd {
    float: left;
}
.bl_caseInfo dd {
    float: none;
}
/* ====================================
  案件情報
==================================== */
.el_mapImg {
    width: 30%;
}
.bl_sect_frame__pd0 {
    padding: 0 !important;
    background: #fff;
    margin-bottom: 55px;
}
.bl_sect_frame__pd0:last-child {
    /*margin-bottom: 0;*/
}
.bl_acoBtnWrapper {
    flex: 1;
    position: relative;
    font-size: 2.5rem;
    display: inline-block;
    max-width: 250px;
    padding: 0;
    margin-right: 15px;
    background: #fff;
    border: 2px solid #0098ce;
    border-radius: 7px;
    text-align: center;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    line-height: 1.3;
    cursor: pointer;
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}

.bl_acoBtnWrapper button {
    width: 100%;
    margin-right: 0;
    line-height: inherit;
    color: #0098ce;
}
.el_acoBtn__open {
    border-radius: 7px 7px 0 0;
    background-color: #0098ce;
    color: #fff;
}
.el_acoBtn__open button {
    color: #fff;
}
.bl_acoBtnWrapper ul {
    width: calc(100% + 4px);
    position: absolute;
    left: -2px;
    border: 2px solid #0098ce;
    border-radius: 0 0 7px 7px;
    background-color: #fff;
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16);
    z-index: 3;
    font-size: 2.2rem;
}
.bl_acoBtnWrapper li {
    text-align: center;
    border-bottom: 2px solid #0098ce;
}
.bl_acoBtnWrapper li:last-child {
    border-bottom: none;
}
.bl_acoBtnWrapper a {
    display: block;
    padding: 15px 10px 15px;
    color: #0098ce;
}

.bl_sect_applicantList .sticky_table_wrapper {
    margin-bottom: 3vw;
}
.bl_sect_applicantList .el_blueBtn {
    margin-bottom: 20px;
}
/*
  応募者一覧
*/
.el_label__center {
    display: flex;
    justify-content: center;
}
/* ====================================
  応募者詳細
==================================== */
.un_applicantPhoto {
    width: 20%;
    min-width: 100px;
}
.un_applicantPhoto > img {
    width: 100%;
}
.un_applicantVideo {
    width: auto;
    height: auto;
    max-width: 80%;
    max-height: 80%;
}

.un_applicantVideo_inner{
    width: 100%;
}
/*
　応募者登録
*/
.bl_upload_box__applicant {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.bl_upload_box__applicant figure {
    width: 20%;
    min-width: 100px;
}
.bl_upload_box__applicant img {
    width: 100%;
}
.bl_upload_box__applicant > .bl_btns {
    margin-left: 10px;
}
.bl_upload_box__applicant > .bl_btns > label {
    margin: 10px 0;
}
.bl_upload_box__applicant > .bl_btns > .el_smBtn__og {
    background-color: #f59511;
    color: #fff;
}
.bl_upload_box__applicant > .bl_btns > .el_smBtn__og:hover {
    background-color: #fff;
    color: #f59511;
}
/* エントリー詳細*/
.bl_tableTxt__l {
    display: flex;
    justify-content: left;
    text-align: left;
}
.bl_horizBtnUnit__inTable {
    margin-top: 0 !important;
}
.un_entryBox {
    border: 1px solid #bfbfbf;
    padding: 20px 20px 5px 20px;
}
.un_entryBox li {
    display: flex;
    position: relative;
    margin-bottom: 15px;
}
.un_entryBox date {
    position: absolute;
}
.un_entryBox p {
    padding-left: 26rem;
}
.bl_horizBtnUnit__inTable button {
    white-space: nowrap;
}
/* ====================================
  モーダル
==================================== */
html.modalset {
    overflow: hidden;
}
.bl_modal {
    display: block;
    width: 100%;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9999;
}
.bl_modal-wrap {
    width: 100%;
    height: 100%;
    text-align: center;
    padding: 20px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    white-space: nowrap;
}
.bl_modal-wrap::-webkit-scrollbar {
    display: none;
}
.bl_modal-wrap:after {
    content: "";
    display: inline-block;
    vertical-align: middle;
    width: 1px;
    height: 100%;
}
.bl_modal-bg {
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(51, 51, 51, 0.8);
    width: 100%;
    height: 100%;
}
.bl_modal-box {
    width: 100%;
    max-width: 700px;
    display: inline-block;
    vertical-align: middle;
    position: relative;
}
.bl_modal-box .bl_modal_inner {
    background: #fff;
    text-align: left;
    border-radius: 10px;
}
.bl_modal-box .bl_modal_inner > :first-child {
    margin-top: 0;
}
.bl_modalTop {
    text-align: center;
    border-radius: 10px 10px 0px 0;
    padding: 20px;
    border-bottom: 1px solid #bfbfbf;
}
.bl_modalTop img {
    width: 50px;
}
.bl_modalTop p {
    margin-top: 15px;
    font-size: 2.8rem;
}
.bl_modalBtm {
    padding: 20px;
    background-color: #f0f0f0;
    border-radius: 0px 0px 10px 10px;
}
.bl_modalTxt {
    display: flex;
    justify-content: center;
    text-align: left;
    white-space: initial;
    line-height: 1.4;
}
.bl_modal-box_osl .bl_modalTop p {
    margin-top: 0;
}
.bl_modal-box_osl .bl_modalTxt {
    display: block;
}
.bl_modal-box_osl .bl_modalTxt p {
    margin-bottom: 2%;
}
.bl_modal-box_osl .sticky_table_wrapper {
    max-height: 40vh;
    width: 100%;
}
/* ====================================
  管理　求人案件登録（条件・業務）
==================================== */
.el_tableNoFrame {
    width: 100%;
    border: none;
    margin: 0;
    padding: 0;
}
.el_tableNoFrame th {
    border: none;
    background: none;
    margin: 0;
    padding: 1%;
    text-align: center;
    font-weight: normal;
}
.el_tableNoFrame td {
    border: none;
    background: none;
    margin: 0;
    padding: 1%;
}
.el_tableNoFrame td input {
    width: 100%;
}
.bl_formFlex {
    display: flex;
    align-items: center;
}
.bl_formFlex > label {
    margin-right: 2%;
}
.el_txtRed {
    color: #fd5618;
}

/* ====================================
  sorry page
==================================== */
.un_sect_sorry .ttl {
    margin-bottom: 2%;
    text-align: center;
    font-size: 2.8rem;
    font-weight: bold;
}
.bl_sorryBody {
    text-align: center;
    line-height: 1.6;
}

.bl_destination {
    display: flex;
    justify-items: center;
}
.bl_destination .el_blueBtn {
    width: 40%;
    height: 6rem;
    margin: 0 5%;
    padding: 0;
    align-self: center;
}
.bl_destination .el_select {
    width: 100%;
    padding: 10px;
    background: none;
}

.bl_formUnit__flex {
    display: flex;
}

/* ====================================
  アンケート登録
==================================== */
.star-rating {
    font-size: 0;
    white-space: nowrap;
    display: inline-block;
    width: 125px;
    height: 25px;
    overflow: hidden;
    position: relative;
    background: url(../Images/star_g.svg);
    background-size: contain;
}
.star-rating i {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 20%;
    z-index: 1;
    background: url(../Images/star_y.svg);
    background-size: contain;
}
.star-rating input {
    -moz-appearance: none;
    -webkit-appearance: none;
    opacity: 0;
    display: inline-block;
    width: 20%;
    height: 100%;
    margin: 0;
    padding: 0;
    z-index: 2;
    position: relative;
}
.star-rating input:hover + i,
.star-rating input:checked + i {
    opacity: 1;
}
.star-rating i ~ i {
    width: 40%;
}
.star-rating i ~ i ~ i {
    width: 60%;
}
.star-rating i ~ i ~ i ~ i {
    width: 80%;
}
.star-rating i ~ i ~ i ~ i ~ i {
    width: 100%;
}
.choice {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    text-align: center;
    padding: 20px;
    display: block;
}
/* ====================================
  アンケート登録
==================================== */
.el_star__og {
    color: #f59511;
}
.el_star__gl {
    color: #bfbfbf;
}
/* ====================================
  アンケート照会
==================================== */
.sticky_table_wrapper .queViewTable_width.sticky_table thead th {
    width: 5rem;
    white-space: initial;
}

/* ====================================
  作業時間
==================================== */
.el_saturDay {
    color: #0084b3;
}
.el_sunDay {
    color: #fd5618;
}
.bl_timeResistBtns {
    display: flex;
    justify-content: center;
}
.bl_timeResistBtns button {
    margin: 25px;
    font-size: 3.6rem;
    display: inline-block;
    width: 250px;
    height: 250px;
    padding-top: 125px;
    background: #fff;
    border: 2px solid #0098ce;
    border-radius: 7px;
    color: #0098ce;
    text-align: center;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    line-height: 1.3;
    cursor: pointer;
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
    position: relative;
}
.bl_timeResistBtns .un_workStart {
    background: #fff url(../Images/time_clock.svg) 50% 25% no-repeat;
    background-size: 30%;
}
.bl_timeResistBtns .un_workEnd {
    background: #fff url(../Images/time_end.svg) 50% 25% no-repeat;
    background-size: 20%;
}
.bl_timeResistBtns .un_restStart {
    background: #fff url(../Images/time_rest_start.svg) 50% 25% no-repeat;
    background-size: 30%;
}
.bl_timeResistBtns .un_restEnd {
    background: #fff url(../Images/time_rest_end.svg) 50% 25% no-repeat;
    background-size: 25%;
}
.un_nowTime {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 30px;
    padding: 20px;
    background-color: #5abcdf;
    background-color: rgba(90, 188, 223, 0.26);
}
.un_nowTime p {
    font-size: 2.8rem;
}

.el_fixedTable {
    width: 800px;
}
.el_fontSize__sm {
    font-size: 1.6rem;
    padding: 5px;
    width: 70%;
}
.sticky_table_workTime .bl_formUnit {
    position: relative;
}
.sticky_table_workTime .bl_formUnit .el_label {
    margin: 0;
    position: absolute;
    width: 16rem;
    top: -5px;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
}
.sticky_table.sticky_table_workTime tbody td {
    padding: 35px 5px 35px;
}
.sticky_table.sticky_table_workTime .bl_formUnit .el_radio_txt,
.bl_formUnit .el_checkbox_txt {
    margin-right: 0;
}
/* ====================================
  進捗管理
==================================== */
.sticky_table tbody td.el_bgColor__ok {
    background-color: #e7ffeb;
}
.sticky_table.el_table_progressRef td.el_bgColor__ok {
    color: rgb(0, 182, 121);
    font-weight: bold;
}

/* ====================================
  ログイン A
==================================== */
body#login_A {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#0098ce+0,48b4ea+100 */
    background-image: url(../Images/top_bg.png);
    background-repeat: no-repeat;
    background-position: right;
    background-size: auto 100%;
    background-color: rgb(0, 152, 206); /* Old browsers */
    background-color: -moz-linear-gradient(
        -45deg,
        rgba(0, 152, 206, 1) 0%,
        rgba(72, 180, 234, 1) 100%
    ); /* FF3.6-15 */
    background-color: -webkit-linear-gradient(
        -45deg,
        rgba(0, 152, 206, 1) 0%,
        rgba(72, 180, 234, 1) 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background-color: linear-gradient(
        135deg,
        rgba(0, 152, 206, 1) 0%,
        rgba(72, 180, 234, 1) 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0098ce', endColorstr='#48b4ea',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}
body#login_A .bl_sect_frame {
    background-color: #fff;
    -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}
body#login_A .el_headerLogo {
    text-align: center;
    height: 80px;
    margin-bottom: 3%;
}
body#login_A .el_headerLogo img {
    width: auto;
    height: 100%;
}
/* ====================================
  ログイン A2-2
==================================== */
body#login_A2-2 {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#0098ce+0,48b4ea+100 */
    background-image: url(../Images/top_bg_town.png);
    background-repeat: repeat-x;
    background-position: bottom;
    background-size: auto auto;
    background-color: rgb(0, 152, 206); /* Old browsers */
    background-color: -moz-linear-gradient(
        -45deg,
        rgba(0, 152, 206, 1) 0%,
        rgba(72, 180, 234, 1) 100%
    ); /* FF3.6-15 */
    background-color: -webkit-linear-gradient(
        -45deg,
        rgba(0, 152, 206, 1) 0%,
        rgba(72, 180, 234, 1) 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background-color: linear-gradient(
        135deg,
        rgba(0, 152, 206, 1) 0%,
        rgba(72, 180, 234, 1) 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0098ce', endColorstr='#48b4ea',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}
body#login_A2-2 .bl_sect_frame {
    background-color: #fff;
    -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}
body#login_A2-2 .el_headerLogo {
    text-align: center;
    height: 80px;
    margin-bottom: 3%;
}
body#login_A2-2 .el_headerLogo img {
    width: auto;
    height: 100%;
}
/* ====================================
  ログイン A2-3
==================================== */
body#login_A2-3 {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#0098ce+0,48b4ea+100 */
    background-image: url(../Images/top_bg_city.png);
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 100% auto;
    background-color: rgb(0, 152, 206); /* Old browsers */
    background-color: -moz-linear-gradient(
        -45deg,
        rgba(0, 152, 206, 1) 0%,
        rgba(72, 180, 234, 1) 100%
    ); /* FF3.6-15 */
    background-color: -webkit-linear-gradient(
        -45deg,
        rgba(0, 152, 206, 1) 0%,
        rgba(72, 180, 234, 1) 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background-color: linear-gradient(
        135deg,
        rgba(0, 152, 206, 1) 0%,
        rgba(72, 180, 234, 1) 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0098ce', endColorstr='#48b4ea',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}
body#login_A2-3 .bl_sect_frame {
    background-color: #fff;
    -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}
body#login_A2-3 .el_headerLogo {
    text-align: center;
    height: 80px;
    margin-bottom: 3%;
}
body#login_A2-3 .el_headerLogo img {
    width: auto;
    height: 100%;
}

/* ====================================
  ログイン A2
==================================== */
body#login_A2 {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#0098ce+0,48b4ea+100 */
    background-color: rgb(0, 152, 206); /* Old browsers */
    background-color: -moz-linear-gradient(
        -45deg,
        rgba(0, 152, 206, 1) 0%,
        rgba(72, 180, 234, 1) 100%
    ); /* FF3.6-15 */
    background-color: -webkit-linear-gradient(
        -45deg,
        rgba(0, 152, 206, 1) 0%,
        rgba(72, 180, 234, 1) 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background-color: linear-gradient(
        135deg,
        rgba(0, 152, 206, 1) 0%,
        rgba(72, 180, 234, 1) 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0098ce', endColorstr='#48b4ea',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}
body#login_A2 .bl_sect_frame {
    background-color: #fff;
    -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

/* ====================================
  ログイン B
==================================== */
body#login_B {
    background-image: url(../Images/40493278.jpg);
    background-size: cover;
}
body#login_B .bl_sect_frame {
    background-color: rgba(249, 249, 249, 0.9);
    -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}
/* ====================================
  ログイン C
==================================== */
body#login_C .bl_mainImage {
    height: 380px;
    width: 100%;
    background-image: url(../Images/40493278.jpg);
    background-size: cover;
    background-position: center center;
}
body#login_C .ly_cont_login .ly_mainCont_inner {
    margin-top: 2vw;
}
body#login_C .ly_cont_login .el_aside {
    margin-bottom: 0;
}
body#login_C .bl_sect_frame {
    -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

/* ====================================
  ログイン C2
==================================== */
body#login_C2 .bl_mainImage {
    height: 380px;
    width: 100%;
    background-image: url(../Images/40493278.jpg);
    background-size: cover;
    background-position: center center;
    background-color: rgba(255, 255, 255, 0.2);
    background-blend-mode: lighten;
}
body#login_C2 .ly_cont_login .ly_mainCont_inner {
    margin-top: 2vw;
}
body#login_C2 .ly_cont_login .el_aside {
    margin-bottom: 0;
}
body#login_C2 .bl_mainImage {
    text-align: center;
}
body#login_C2 .bl_mainImage img {
    margin-top: 90px;
}
body#login_C2 .bl_sect_frame {
    -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

/* ====================================
  media query
==================================== */
@media print, screen and (min-width: 576px) {
    /* ====================================
    display: none
  ==================================== */
    .none-md {
        display: none !important;
    }
    /* ====================================
    layout
  ==================================== */
    /* content
  ===================================== */
    .ly_cont_side {
        width: 390px;
        min-height: 100%;
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0);
    }
    .ly_cont_side.is_active_pc {
        -webkit-transform: translateX(-390px);
        -ms-transform: translateX(-390px);
        transform: translateX(-390px);
    }
    .ly_cont_main {
        width: calc(100% - 390px);
        -webkit-transform: translateX(390px);
        -ms-transform: translateX(390px);
        transform: translateX(390px);
        -webkit-transition: -webkit-transform 0.3s;
        transition: -webkit-transform 0.3s;
        -o-transition: transform 0.3s;
        transition: transform 0.3s;
        transition: transform 0.3s, -webkit-transform 0.3s;
    }
    .ly_cont_main.is_wide {
        width: 100%;
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0);
        -webkit-transition: -webkit-transform 0.3s;
        transition: -webkit-transform 0.3s;
        -o-transition: transform 0.3s;
        transition: transform 0.3s;
        transition: transform 0.3s, -webkit-transform 0.3s;
    }
    .ly_cont_main.is_wide .bl_sect_inner {
        margin-right: 5.20833vw;
        margin-left: 5.20833vw;
    }

    /* ====================================
    block
  ==================================== */
    /* breadcrumb
  ===================================== */
    .bl_breadcrumb_list li a:hover {
        text-decoration: underline;
    }
    /* side navi
  ===================================== */
    .bl_gnav_link:hover {
        background: #35b6de;
        cursor: pointer;
        -webkit-transition: 0.3s;
        -o-transition: 0.3s;
        transition: 0.3s;
    }
    .bl_gnav_btn:hover {
        background: #35b6de url(../Images/icon_arrow_down_w.svg)
            calc(100% - 27px) calc(50% + 4px) no-repeat;
        cursor: pointer;
        -webkit-transition: 0.3s;
        -o-transition: 0.3s;
        transition: 0.3s;
    }
    .bl_gnavChildNav_link:hover {
        background: #f8f7f8 url(../Images/icon_listmark_blue.svg) 83px center
            no-repeat;
        color: #0098ce;
        -webkit-transition: 0.3s;
        -o-transition: 0.3s;
        transition: 0.3s;
    }
    .bl_memberNav_link:hover {
        background: #4e6e79;
        -webkit-transition: 0.3s;
        -o-transition: 0.3s;
        transition: 0.3s;
    }
    .bl_utilNav_link:hover {
        background: #4e6e79;
        -webkit-transition: 0.3s;
        -o-transition: 0.3s;
        transition: 0.3s;
    }
    /* step bar
  ===================================== */
    .bl_stepbar_link:hover {
        background: #0098ce;
        color: #fff;
    }
    .bl_stepbar_link:hover:after {
        position: absolute;
        display: block;
        content: "";
        top: -0.5px;
        right: -26px;
        width: 27px;
        height: 100%;
        background: url(../Images/img_step_blue.png) top left no-repeat;
        background-size: 100% calc(100% + 1px);
        z-index: 1;
    }
    /* tab
  ===================================== */
    .bl_tabs_link:hover {
        background: #0098ce;
        color: #fff;
        -webkit-transition: 0.3s;
        -o-transition: 0.3s;
        transition: 0.3s;
        text-decoration: none;
    }
    /* image image
  ===================================== */
    .bl_upload_ttl:hover {
        opacity: 0.7;
        -webkit-transition: 0.3s;
        -o-transition: 0.3s;
        transition: 0.3s;
    }
    /* お知らせ
  ===================================== */
    .bl_vertPosts_link:hover {
        text-decoration: none;
    }
    /* button
  ===================================== */
    .el_submitBtn__plus:hover {
        background: #fff url(../Images/icon_plus_blue.svg) calc(100% - 15px)
            center no-repeat !important;
    }
    .el_submitBtn:hover {
        background: #fff;
        color: #0098ce;
        -webkit-transition: 0.3s;
        -o-transition: 0.3s;
        transition: 0.3s;
        text-decoration: none;
    }
    .el_actBtn:hover {
        background: #fff;
        color: #f59511;
        -webkit-transition: 0.3s;
        -o-transition: 0.3s;
        transition: 0.3s;
        text-decoration: none;
    }
    .el_backBtn:hover {
        background: #0098ce;
        color: #fff;
        -webkit-transition: 0.3s;
        -o-transition: 0.3s;
        transition: 0.3s;
        text-decoration: none;
    }
    .bl_horizBtnUnit__blue > .el_backBtn:hover {
        background: #0098ce url(../Images/icon_arrow_back_w.svg) 20px center
            no-repeat;
    }
    .el_nextBtn:hover {
        background: #0098ce url(../Images/icon_arrow_next_w.svg)
            calc(100% - 20px) center no-repeat;
        color: #fff;
        -webkit-transition: 0.3s;
        -o-transition: 0.3s;
        transition: 0.3s;
        text-decoration: none;
    }
    .el_saveBtn:hover {
        background: #fff url(../Images/icon_save_og.svg) calc(100% - 20px)
            center no-repeat;
        color: #f59511;
        -webkit-transition: 0.3s;
        -o-transition: 0.3s;
        transition: 0.3s;
        text-decoration: none;
    }
    .el_closeBtn:hover {
        background: #0098ce url(../Images/icon_close_up_w.svg) calc(100% - 22px)
            center no-repeat;
        color: #fff;
        -webkit-transition: 0.3s;
        -o-transition: 0.3s;
        transition: 0.3s;
    }
    .el_openBtn:hover {
        background: #0098ce url(../Images/icon_open_dwn_w.svg) calc(100% - 22px)
            center no-repeat;
        color: #fff;
        -webkit-transition: 0.3s;
        -o-transition: 0.3s;
        transition: 0.3s;
    }
    /* 汎用 button
  ===================================== */
    .el_btn:hover {
        background: #f59511;
        color: #fff;
        -webkit-transition: 0.3s;
        -o-transition: 0.3s;
        transition: 0.3s;
        text-decoration: none;
    }
    .el_btn__bg:hover {
        border-color: #fff;
        background: #fff;
        color: #f59511;
        -webkit-transition: 0.3s;
        -o-transition: 0.3s;
        transition: 0.3s;
    }
    .el_btn__clear:hover {
        border-color: #f59511;
        background: #f59511;
        color: #fff;
        -webkit-transition: 0.3s;
        -o-transition: 0.3s;
        transition: 0.3s;
    }
    .el_blueBtn:hover {
        background: #0098ce;
        color: #fff;
        -webkit-transition: 0.3s;
        -o-transition: 0.3s;
        transition: 0.3s;
        text-decoration: none;
    }
    .el_blueBtn__bg:hover {
        border-color: #fff;
        background: #fff;
        color: #0098ce;
        -webkit-transition: 0.3s;
        -o-transition: 0.3s;
        transition: 0.3s;
    }
    .el_blueBtn__clear:hover {
        border-color: #0098ce;
        background: #0098ce;
        color: #fff;
        -webkit-transition: 0.3s;
        -o-transition: 0.3s;
        transition: 0.3s;
    }
    .el_blueBtn__wide {
        max-width: 306px;
    }
    .el_smBtn:hover {
        background: #f59511;
        color: #fff;
        -webkit-transition: 0.3s;
        -o-transition: 0.3s;
        transition: 0.3s;
    }
    .el_smBtn__bg:hover {
        border-color: #fff;
        background: #fff;
        color: #f59511;
        -webkit-transition: 0.3s;
        -o-transition: 0.3s;
        transition: 0.3s;
    }
    .el_smBtn__clear:hover {
        border-color: #f59511;
        background: #f59511;
        color: #fff;
        -webkit-transition: 0.3s;
        -o-transition: 0.3s;
        transition: 0.3s;
    }
    .el_smBlueBtn:hover {
        background: #0098ce;
        color: #fff;
        -webkit-transition: 0.3s;
        -o-transition: 0.3s;
        transition: 0.3s;
    }
    .el_smBlueBtn__bg:hover {
        border-color: #fff;
        background: #fff;
        color: #0098ce;
        -webkit-transition: 0.3s;
        -o-transition: 0.3s;
        transition: 0.3s;
    }
    .el_smBlueBtn__clear:hover {
        border-color: #0098ce;
        background: #0098ce;
        color: #fff;
        -webkit-transition: 0.3s;
        -o-transition: 0.3s;
        transition: 0.3s;
    }
    .el_iconBtn:hover {
        background: #f59511;
        color: #fff;
        -webkit-transition: 0.3s;
        -o-transition: 0.3s;
        transition: 0.3s;
    }
    .el_iconBlueBtn:hover {
        background: #0098ce;
        color: #fff;
        -webkit-transition: 0.3s;
        -o-transition: 0.3s;
        transition: 0.3s;
    }
    /* text link
  ===================================== */
    .el_link:hover {
        text-decoration: none;
    }
    /* ====================================
    ログイン画面
  ==================================== */
    .ly_cont_login {
        width: 100%;
        transform: none;
    }
    /* 案件一覧
  */
    .el_searchBtn__open:hover {
        background: url(../Images/icon_search_dwn_w.svg) calc(100% - 22px)
            center no-repeat;
        background-color: #0098ce;
        color: #fff;
    }
    .el_searchBtn__close:hover {
        background: url(../Images/icon_search_up_blue.svg) calc(100% - 22px)
            center no-repeat;
        background-color: #fff !important;
        color: #0098ce !important;
    }
    /* 案件情報
  */
    .bl_acoBtnWrapper:hover {
        background-color: #0098ce;
    }
    .el_acoBtn__open:hover {
        color: #fff;
    }
    .bl_acoBtnWrapper button:hover {
        color: #fff;
    }
    .bl_acoBtnWrapper a:hover {
        background-color: #0098ce;
        color: #fff;
    }
    /* ====================================
    作業時間
  ==================================== */
    .bl_timeResistBtns button:hover {
        background-color: #0098ce;
        color: #fff;
    }
    .bl_timeResistBtns .un_workStart:hover {
        background: #0098ce url(../Images/time_clock_w.svg) 50% 25% no-repeat;
        background-size: 30%;
    }
    .bl_timeResistBtns .un_workEnd:hover {
        background: #0098ce url(../Images/time_end_w.svg) 50% 25% no-repeat;
        background-size: 20%;
    }
    .bl_timeResistBtns .un_restStart:hover {
        background: #0098ce url(../Images/time_rest_start_w.svg) 50% 25%
            no-repeat;
        background-size: 30%;
    }
    .bl_timeResistBtns .un_restEnd:hover {
        background: #0098ce url(../Images/time_rest_end_w.svg) 50% 25% no-repeat;
        background-size: 25%;
    }
}

@media screen and (max-width: 575px) {
    /* ====================================
    display: none
  ==================================== */
    .none-d-md {
        display: none !important;
    }
    /* ====================================
    base
  ==================================== */
    html {
        font-size: 31.25%;
    }
    /* ====================================
    layout
  ==================================== */
    /* contents
  ===================================== */
    .ly_cont_side {
        position: fixed;
        width: 100%;
        height: 100%;
        background: #fff;
        overflow-y: auto;
        -webkit-transform: translateX(-100%);
        -ms-transform: translateX(-100%);
        transform: translateX(-100%);
    }
    .ly_cont_side.is_active_sp {
        width: 100%;
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0);
    }
    .ly_cont_main.is_fixed_sp {
        position: fixed;
    }
    /* header
  ===================================== */
    .ly_header {
        height: 28px;
        padding-left: 15px;
    }
    .ly_header img {
        width: 90px;
    }
    /* main contents
  ===================================== */
    .ly_mainCont_inner {
        margin: 10px 10px 0;
        padding-bottom: 60px;
    }
    .ly_mainCont_grayInner {
        padding: 30px 10px 40px;
    }
    /* ==========================================
    block
  ========================================== */
    /* accordion
  ===================================== */
    .bl_accord_header img {
        width: 20px;
    }
    /* breadcrumb
  ===================================== */
    .bl_breadcrumb {
        margin-top: 10px;
        margin-bottom: 30px;
    }
    .bl_breadcrumb {
        margin: 10px 10px 30px;
    }
    /* button block
  ===================================== */
    .bl_horizBtnUnit {
        margin-top: 20px;
    }
    .bl_horizBtnUnit button {
        margin-right: 5px;
        margin-left: 5px;
    }
    /* form block
  ===================================== */
    .bl_formUnit {
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }
    .bl_formUnit .el_input,
    .bl_formUnit .el_radio_txt,
    .bl_formUnit .el_checkbox_txt {
        margin-bottom: 5px;
    }
    .bl_formTxt_btm {
        margin-top: 5px;
    }
    /* side navi
  ===================================== */
    .bl_gnav_item.is_active .bl_gnav_btn {
        background-size: 16px;
    }
    .bl_gnav_item.is_active .bl_gnavChildNav_item {
        padding: 5px 0;
    }
    .bl_gnav_item.is_active .bl_gnavChildNav_link {
        padding: 5px 0 5px 65px;
    }
    .bl_gnav_link {
        height: 44px;
    }
    .bl_gnav_btn {
        height: 44px;
        background-size: 16px;
    }
    .bl_gnav_icon {
        width: 18px;
        margin-right: 10px;
    }
    .bl_gnav_icon img {
        max-width: 100%;
    }
    .bl_gnavChildNav_link {
        background: url(../Images/icon_listmark.svg) 41.5px center no-repeat;
        background-size: 11.5px;
    }
    .bl_gnavChildNav_link.is_current {
        background: #f8f7f8 url(../Images/icon_listmark_blue.svg) 41.5px center
            no-repeat;
        background-size: 11.5px;
    }
    .bl_memberNav_link {
        height: 37.5px;
    }
    .bl_memberNav_icon {
        width: 18px;
    }
    .bl_memberNav_icon img {
        max-width: 100%;
    }
    .bl_utilNav_item {
        height: 47px;
    }
    .bl_utilNav_link {
        padding: 7px 0 10px;
    }
    .bl_utilNav_icon {
        width: 18px;
        margin-bottom: 5px;
    }
    .bl_utilNav_icon img {
        max-width: 100%;
    }
    /* toppage contents
  ===================================== */
    .bl_info {
        margin-bottom: 40px;
        padding-bottom: 50px;
    }
    .bl_info_header {
        padding: 10px 20px;
    }
    .bl_info_ttl img {
        width: 20px;
        margin-right: 10px;
    }
    .bl_info_main {
        padding: 20px 30px;
    }
    /* main header
  ===================================== */
    .bl_mainHeader {
        margin-bottom: 10px;
        padding-top: 8px;
    }
    .bl_mainHeader_inner {
        margin: 0;
        padding: 0 10px;
    }
    .bl_mainHeader_linkUnit {
        position: absolute;
        right: 10px;
        margin: 0;
    }
    .bl_mainHeader_link img {
        width: 21.5px;
        margin-left: 5px;
    }
    /* media
  ===================================== */
    .bl_media_img {
        margin-right: 10px;
    }
    .bl_media_video {
        margin-right: 10px;
    }
    /* seciton
  ===================================== */
    .bl_sect_header {
        margin-bottom: 15px;
    }
    .bl_sect_frame {
        padding: 20px 10px 30px;
    }
    .bl_sect_box {
        padding: 20px 10px;
    }
    /* side close button
  ===================================== */
    .bl_sideCloseBtn_wrapper {
        margin: 0 0 0 auto;
        padding: 7.5px 7.5px 7.5px 0;
        background: #0098ce;
    }
    .bl_sideCloseBtn img {
        width: 22px;
    }
    /* side open button
  ===================================== */
    .bl_sideOpenBtn_wrapper {
        left: -8px;
        top: 34px;
    }
    .bl_sideOpenBtn img {
        width: 70px;
    }
    /* step bar
  ===================================== */
    .bl_stepbar_item:first-child .bl_stepbar_link {
        padding-left: 10px;
    }
    .bl_stepbar_item:not(:first-child):before {
        width: 10px;
    }
    .bl_stepbar_item.is_current:before {
        width: 10px;
    }
    .bl_stepbar_item.is_current:after {
        right: -9px;
        width: 10px;
    }
    .bl_stepbar_item.is_disabled:before {
        width: 10px;
    }
    .bl_stepbar_item span {
        padding-left: 12px;
    }
    .bl_stepbar_link {
        padding-left: 15px;
    }
    /* table 水平
  ===================================== */
    .bl_horizTable > tbody > tr > th,
    .bl_horizTable > thead > tr > th,
    .bl_horizTable > tfoot > tr > th {
        padding-top: 1vh;
        padding-bottom: 1vh;
    }
    .bl_horizTable > tbody > tr > td,
    .bl_horizTable > thead > tr > td,
    .bl_horizTable > tfoot > tr > td {
        padding-top: 1vh;
        padding-bottom: 1vh;
    }
    .bl_horizTable02 > tbody > tr > th,
    .bl_horizTable02 > thead > tr > th,
    .bl_horizTable02 > tfoot > tr > th {
        width: 20%;
        padding: 10px;
    }
    .bl_horizTable02 > tbody > tr > td,
    .bl_horizTable02 > thead > tr > td,
    .bl_horizTable02 > tfoot > tr > td {
        padding: 10px;
    }
    /* tab
  ===================================== */
    .bl_tabs {
        margin-top: 30px;
    }
    .bl_tabs_list {
        margin-bottom: 30px;
    }
    .bl_tabs_list li {
        margin-right: 10px;
        margin-left: 10px;
    }
    .bl_tabs_link {
        padding: 10px;
    }
    .bl_tabs_link {
        border-width: 1.5px;
    }
    .bl_tabs_sect {
        margin-bottom: 30px;
    }
    .bl_tabs_ttl {
        margin-bottom: 20px;
        padding: 10px;
    }
    /* todo list
  ===================================== */
    .bl_todo_item {
        margin-bottom: 15px;
        padding: 10px 40px 10px 20px;
    }
    .bl_todo_item.is_error {
        background: #fdc9c0 url(../Images/icon_erro_rd03.svg) calc(100% - 15px)
            center no-repeat;
        background: rgba(253, 201, 192, 0.26) url(../Images/icon_erro_rd03.svg)
            calc(100% - 15px) center no-repeat;
        background-size: 20px;
    }
    .bl_todo_main {
        margin-left: 10px;
    }
    /* image upload
  ===================================== */
    .bl_upload {
        margin: 20px auto 30px;
    }
    .bl_upload_ttl {
        padding: 5px 10px 5px 20px;
    }
    .bl_upload_del img {
        width: 10px;
    }
    .bl_upload_img {
        width: 50%;
    }
    /* お知らせ
  ===================================== */
    .bl_vertPosts_item {
        margin-bottom: 20px;
    }
    /* button
  ===================================== */
    .el_backBtn {
        max-width: 130px;
        margin-right: 5px;
        margin-left: 5px;
        border-width: 1.5px;
        background-position: 10px center;
        background-size: 17px;
    }
    .bl_horizBtnUnit .el_backBtn {
        max-width: 130px;
    }
    .bl_horizBtnUnit__blue > .el_backBtn {
        max-width: 130px;
        margin-right: 5px;
        margin-left: 5px;
        border-width: 1.5px;
        background-position: 10px center;
        background-size: 17px;
    }

    .el_nextBtn {
        max-width: 130px;
        margin-right: 5px;
        margin-left: 5px;
        border-width: 1.5px;
        background-position: calc(100% - 10px) center;
        background-size: 17px;
    }
    .el_saveBtn {
        max-width: 130px;
        margin-right: 5px;
        margin-left: 5px;
        border-width: 1.5px;
        background-position: calc(100% - 10px) center;
        background-size: 17px;
    }
    .el_funcBtn img {
        width: 40px;
    }
    .el_closeBtn {
        min-width: 101px;
        padding: 6px 36px 4px 35px;
        border-width: 1.5px;
        background-size: 12px;
        background-position: calc(100% - 12px) center;
    }
    .el_openBtn {
        background-size: 12px;
    }
    /* 汎用ボタン
  ===================================== */
    .el_btn {
        max-width: 130px;

        /*min-width: 143px;*/
        border-width: 1.5px;
    }
    .bl_horizBtnUnit .el_btn {
        max-width: 130px;
    }
    .el_btn.is_disabled {
        border-width: 1.5px;
    }
    .el_blueBtn {
        max-width: 130px;
        /*min-width: 143px;*/
        border-width: 1.5px;
    }
    .el_blueBtn.is_disabled {
        border-width: 1.5px;
    }
    .el_blueBtn__clear {
        border-width: 1.5px;
    }
    .el_smBtn {
        min-width: 101px;
        border-width: 1.5px;
    }
    .el_smBtn__clear {
        border-width: 1.5px;
    }
    .el_smBtn.is_disabled {
        border-width: 1.5px;
    }
    .el_smBlueBtn {
        min-width: 101px;
        border-width: 1.5px;
    }
    .bl_horizBtnUnit__inTable .el_smBlueBtn {
        min-width: auto;
    }
    .el_smBlueBtn__clear {
        border-width: 1.5px;
    }
    .el_smBlueBtn.is_disabled {
        border-width: 1.5px;
    }
    .el_iconBtn {
        padding-right: 15px;
        padding-left: 15px;
        border-width: 1.5px;
    }
    .el_iconBtn img {
        height: 15px;
    }
    .el_iconBlueBtn {
        padding-right: 15px;
        padding-left: 15px;
        border-width: 1.5px;
    }
    .el_iconBlueBtn img {
        height: 15px;
    }
    /* form
  ===================================== */
    .el_input {
        height: 34px;
    }
    .el_input__sm {
        max-width: 85px;
    }
    .el_input__md {
        max-width: 110px;
    }
    .el_input__lg {
        max-width: 145px;
    }
    .el_input__max {
        max-width: 100%;
    }
    .el_input__datepicker {
        max-width: 100px;
        background-size: 11px;
    }
    .el_input__num {
        max-width: 70px;
    }
    .el_input__numSm {
        max-width: 60px;
    }
    .el_input {
        width: 90%;
        padding: 7px 10px 6px;
    }
    .el_select {
        width: auto;
        padding: 10px 30px 8px 8px;
        background-size: 11px;
    }
    .el_input__countryCode {
        margin-right: 5px;
        width: 40px;
    }
    .el_input__phoneNum {
        width: 50px;
    }
    .el_checkbox_txt {
        font-size: 2rem;
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        height: auto;
        padding-left: 15px;
        padding-bottom: 3px;
        line-height: 1.4;
        cursor: pointer;
    }
    .el_checkbox_txt:before {
        margin-right: 5px;
        content: "";
        display: block;
        position: absolute;
        top: 2px;
        left: 0;
        width: 9px;
        height: 9px;
        background: #fff;
        border: 1px solid #bfbfbf;
    }
    .el_checkbox:checked + .el_checkbox_txt:before {
        position: absolute;
        top: 2px;
        width: 9px;
        height: 9px;
    }
    .el_checkbox:checked + .el_checkbox_txt:after {
        top: 0px;
        left: 3px;
        width: 4px;
        height: 9px;
        border-width: 2px;
    }
    .el_label:hover .el_checkbox:checked + .el_checkbox_txt:after {
        border-width: 2px;
    }
    .el_checkbox_txt__solo {
        min-height: 11px;
        max-width: 9px;
    }
    .el_checkbox_txt__solo:before {
        margin-right: 5px;
        content: "";
        display: block;
        position: absolute;
        top: calc(50% - 7px);
        left: calc(50% - 7px);
        width: 9px;
        height: 9px;
        background: #fff;
        border: 1px solid #bfbfbf;
    }
    .el_checkbox:checked + .el_checkbox_txt__solo:before {
        position: absolute;
        top: calc(50% - 7px);
        left: calc(50% - 7px);
        width: 9px;
        height: 9px;
    }
    .el_checkbox:checked + .el_checkbox_txt__solo:after {
        top: calc(50% - 9px);
        left: calc(50% - 4px);
        width: 4px;
        height: 9px;
        border-width: 2px;
    }
    /* 見出し
  ===================================== */
    .el_lv1Heading {
        margin-top: 65px;
        line-height: 1.4;
    }
    .el_lv2Heading:before {
        width: 4px;
        margin-right: 8px;
    }
    .el_lv3Heading {
        padding-bottom: 8px;
    }
    .el_lv3Heading:before {
        width: 4px;
        margin-right: 8px;
    }
    /* ==========================================
    unique
  ========================================== */
    /* top page contents
  ===================================== */
    .un_topMainCont {
        margin: 0 20px;
        padding-top: 70px;
    }
    .bl_footer {
        margin: 0 20px;
        padding: 10px 0;
    }
    .el_osl {
        padding-bottom: 8px;
    }

    /* 次へボタン画像
  ===================================== */
    .un_nextBtnImg {
        height: 16px;
    }
    .un_nextBtnImg img {
        max-height: 100%;
    }
    /* 検索アコーディオンボタン
  ===================================== */
    .el_searchBtn__open,
    .el_searchBtn__open:hover {
        background-size: 11px;
    }
    .el_searchBtn__close,
    .el_searchBtn__close:hover {
        background-size: 11px;
    }
    /* ヘッダユーザー情報
  ===================================== */
    .el_headerUserInfo {
        right: 15px;
    }
    /* error area
  ===================================== */
    #errorarea {
        padding: 5px 10px;
    }
    #errorarea li,
    #errorarea p {
        padding-left: 15px;
        background-size: 10px;
    }

    /* インターン概要
  ===================================== */
    .el_list_blue li:before {
        position: absolute;
        top: calc((1.3em - 5px) / 2);
        left: 0;
        content: "";
        display: block;
        background: #009ddb;
        border-radius: 50%;
        width: 4px;
        height: 4px;
    }
    .el_numlist .el_comment {
        display: block;
        position: relative;
        background: #009ddb;
        margin: 12px 0 22px;
        border-radius: 5px;
        padding: 0 0.8em;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        font-size: 1.8rem;
        font-weight: 400;
        color: #fff;
    }
    .el_numlist .el_comment:before {
        top: -5px;
        left: 20px;
    }
    .bl_list_spsv .el_checkbox_txt:before {
        top: 4px;
    }
    .bl_list_spsv .el_checkbox:checked + .el_checkbox_txt:before {
        top: 4px;
    }
    .bl_list_spsv .el_checkbox:checked + .el_checkbox_txt:after {
        top: 3px;
    }

    /* ログイン画面
  ===================================== */
    .ly_cont_login .el_input {
        width: 100%;
    }
    body#login_A2-2 .el_headerLogo {
        text-align: center;
        height: 40px;
        margin-bottom: 3%;
    }
    /* 案件一覧
  ===================================== */
    .bl_horizBtnUnit__multi button {
        min-width: 1rem;
    }
    /* sticky table */
    .sticky_table_wrapper {
        overflow: scroll;
        width: calc(100vw - 20px);
        max-height: 50vh;
    }
    .sticky_table {
        /*width: 802px;*/
        border-collapse: collapse;
        border: 1px solid #bfbfbf;
    }
    .sticky_table thead tr:nth-child(1) th {
        top: -1px;
    }
    .sticky_table thead tr:nth-child(2) th {
        top: 55px; /* 2行目は1行目の高さの位置に固定する */
    }

    .bl_searchBtn button {
        max-width: 100%;
        border: 1px solid #0098ce;
    }
    .bl_acoBtnWrapper {
        margin-right: 1rem;
        border: 1px solid #0098ce;
    }
    .bl_acoBtnWrapper.el_acoBtn__open {
    }
    .bl_acoBtnWrapper ul {
        border: 1px solid #0098ce;
        width: calc(100% + 2px);
        left: -1px;
    }
    .bl_acoBtnWrapper li {
        border-bottom: 1px solid #0098ce;
    }

    .el_multiFlexTxt {
        width: 20%;
    }
    /* 作業時間
  ===================================== */
    .bl_timeResistBtns button {
        margin: 10px;
        height: 125px;
        padding-top: 70px;
    }
}

.text_right_align {
    text-align:right;
    ime-mode: inactive;
}